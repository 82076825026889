import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Div } from '../../framework';
import { InvoiceItemBase, RequestPaymentInvoice } from '../../helper/type';
import { openModal } from '../../store/app.slice';
import { roundDecimalPlace } from '../../helper/object';

type Props = { invoice: RequestPaymentInvoice };

const HirerInvoiceItems: FC<Props> = ({ invoice }) => {
  const dispatch = useDispatch();

  const viewAttachments = (attachments: InvoiceItemBase['attachments']) => {
    if (!attachments?.length) return;

    dispatch(
      openModal({
        modal: 'media-gallery',
        config: {
          context: {
            gallery: attachments.map(({ url }) => ({
              original: url,
              thumbnail: url,
            })),
            index: 0,
          },
        },
      })
    );
  };

  return (
    <Div>
      {(invoice.items ?? []).map((item, idx) => (
        <Div key={`${invoice.id}-${idx}`} flex mb='m' className='invoice-item'>
          <Div>
            <Div>{item.description}</Div>
            {item.attachments?.length ? (
              <Button
                className='btn-view-receipt'
                onClick={() => viewAttachments(item.attachments)}
              >
                View Receipt
              </Button>
            ) : null}
          </Div>
          <span>MYR {roundDecimalPlace(item.amount, 2).toFixed(2)}</span>
        </Div>
      ))}
    </Div>
  );
};

export default HirerInvoiceItems;
