import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Empty } from 'antd';
import HirerToRateCard from '../../../component/Card/HirerToRateCard/HirerToRateCard';
import { Button, Div } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { useRatingService } from '../../../service/rating.service';
import { RootState } from '../../../store/store';
import { setPendingRatings } from '../../../store/hirer.slice';

const ToRateTabContent = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const ratingService = useRatingService();
  const { pendingRatings } = useSelector((state: RootState) => state.hirer);
  const { authHirer } = useSelector((state: RootState) => state.user);

  const [listKey, setListKey] = useState<string>();

  useEffect(() => {
    (async () => {
      const { data, key } = await ratingService.listPendingToRate(
        authHirer.hirerId
      );

      dispatch(setPendingRatings(data));
      setListKey(key);
    })();
  }, []);

  const loadMorePendingRatings = async () => {
    const { data, key } = await ratingService.listPendingToRate(
      authHirer.hirerId,
      listKey
    );

    dispatch(setPendingRatings(pendingRatings.concat(data)));
    setListKey(key);
  };

  return (
    <Div className='tab-hirer-to-rate'>
      {pendingRatings.length > 0 ? (
        pendingRatings.map((pr) => (
          <HirerToRateCard
            key={`${pr.roleId}-${pr.talentId}`}
            projectId={pr.projectId}
            roleId={pr.roleId}
            talentId={pr.talentId}
            header
            action
          />
        ))
      ) : (
        <Empty description={<>No talent to review</>} />
      )}
      {listKey && (
        <Button
          block
          onClick={loadMorePendingRatings}
          style={{ marginTop: '2rem' }}
        >
          Load More
        </Button>
      )}
    </Div>
  );
};

export default ToRateTabContent;
