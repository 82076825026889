import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography, Button } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import UserInfo, {
  UserInfoValues,
} from '../../../component/HirerForm/UserInfo';
import { Div, Page } from '../../../framework';
import { getEmailProviderUrl } from '../../../helper/getEmailProviderUrl';
import { useResetScroll } from '../../../helper/hook';
import { getMilliSeconds } from '../../../helper/object';
import { Project, Role } from '../../../helper/type';
import { HIRER_ROUTES } from '../../../route/constant';
import { useSstAuthService } from '../../../service/auth.sst.service';
import { useHirerService } from '../../../service/hirer.service';

import './Register.scss';

const { Title, Paragraph } = Typography;

const Register = () => {
  useResetScroll();
  const hirerService = useHirerService();
  const sstAuthService = useSstAuthService();
  const { state: locationState } = useLocation<{
    savedProject: Project;
    savedRoles: Role[];
  }>();

  const saveJobPost = Boolean(locationState);

  const [error, setError] = useState('');
  const [email, setEmail] = useState<string>();
  const [emailSent, setEmailSent] = useState(false);

  const onFinish = async (values: UserInfoValues) => {
    const { name, email, contactNumber } = values;
    try {
      const { exists } = await hirerService.emailExists(email);
      if (exists) {
        setError('Email already exists.');
        return;
      }

      let redirectState = '';
      if (saveJobPost) {
        const { savedProject, savedRoles } = locationState;
        const savedData = JSON.stringify({ savedProject, savedRoles });
        redirectState = `?state=${encodeURIComponent(savedData)}`;
      }

      const { userId, hirerId } = await hirerService.createUser({
        email,
        name,
        ...contactNumber,
      });

      await sstAuthService.getLink({
        authType: 'hirer_register',
        email,
        linkExpiry: getMilliSeconds({ hours: 24 }),
        redirect: `${HIRER_ROUTES.AUTH}/new${redirectState}`,
        data: { email, name, userId, hirerId, source: 'register' },
      });

      setEmail(email);
      setEmailSent(true);
      setError('');
    } catch (error) {
      const e = error as AxiosError;
      setError(e.response?.data?.message);
    }
  };

  const renderRegisterForm = () => (
    <>
      <Div pt='xxl'>
        <Title level={2} className='text-center'>
          {saveJobPost
            ? 'Continue publishing your project by creating a FREE account'
            : 'Find your next top talent —— Get started with your FREE account today'}
        </Title>
      </Div>

      {saveJobPost && (
        <Paragraph className='text-center'>
          Key in your details below to save your project progress.
        </Paragraph>
      )}

      <UserInfo onFinish={onFinish} formError={error} />

      <Div pb='xl'>
        <Paragraph className='text-center'>
          Already have an account?{' '}
          <Link to={HIRER_ROUTES.LOGIN}>Sign in here</Link>
        </Paragraph>
      </Div>
    </>
  );

  const renderEmailSentFeedback = () => (
    <>
      <Div pt='xxl'>
        <Title level={2} className='text-center'>
          <Div>
            <MailOutlined />
          </Div>
          Check your email!
        </Title>
      </Div>

      <Div className='text-center' pb='xl'>
        <Paragraph>
          We have sent an email with a link to {email} to complete your
          registration process. You can now safely close this tab and continue
          over there.
        </Paragraph>

        <Paragraph>P.S: The link will expire in 24 hours.</Paragraph>
      </Div>

      <Button
        type='primary'
        size='large'
        target='_blank'
        href={
          email
            ? getEmailProviderUrl(email, 'Complete Your Registration Now')
            : undefined
        }
        block
      >
        Open Email App
      </Button>
    </>
  );

  return (
    <Page className='page-hirer-register'>
      {emailSent ? renderEmailSentFeedback() : renderRegisterForm()}
    </Page>
  );
};

export default Register;
