import React, { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import { Page } from '../../../framework';
import { Div } from '../../../framework/Div/Div';
import { HIRER_ROUTES } from 'client/src/route/constant';
import UserInfo, {
  UserInfoValues,
} from '../../../component/HirerForm/UserInfo';
import { getEmailProviderUrl } from '../../../helper/getEmailProviderUrl';
import { getMilliSeconds } from '../../../helper/object';
import { HirerUser, Project } from '../../../helper/type';
import { useHirerService } from '../../../service/hirer.service';
import { useSstAuthService } from '../../../service/auth.sst.service';
import { useJobService } from '../../../service/job.service';

import './ClaimProfile.scss';

const { Title, Paragraph, Text } = Typography;

const ClaimProfilePage: FunctionComponent = () => {
  const hirerService = useHirerService();
  const sstAuthService = useSstAuthService();
  const jobService = useJobService();
  const { search } = useLocation();
  const hirerId = new URLSearchParams(search).get('id') as string;
  const code = new URLSearchParams(search).get('v') as string;

  const [recentProjects, setRecentProjects] = useState<Project[]>([]);
  const [claimedUser, setClaimedUser] = useState<Partial<HirerUser>>();
  const [formError, setFormError] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    (async () => {
      if (!hirerId || !code) {
        setShowError(true);
        return;
      }

      const hirer = await hirerService.getHirer(hirerId);
      if (hirer) {
        setShowError(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (!hirerId) return;

    (async () => {
      const projects: Project[] = await jobService.getProjectsByHirer(hirerId);
      if (projects && projects.length) {
        setRecentProjects(
          projects
            .sort((a, b) => b.closingDate.localeCompare(a.closingDate))
            .slice(0, 3)
        );
      }
    })();
  }, []);

  const onFinish = async (values: UserInfoValues) => {
    const { name, email, contactNumber } = values;
    try {
      const newUser = await hirerService.claimProfile({
        verificationCode: code,
        hirerId,
        name,
        email,
        ...contactNumber,
      });

      await sstAuthService.getLink({
        authType: 'hirer_register',
        email,
        linkExpiry: getMilliSeconds({ hours: 24 }),
        redirect: `${HIRER_ROUTES.AUTH}/new`,
        data: {
          email,
          name,
          hirerId,
          userId: newUser.userId,
          source: 'claim-profile',
        },
      });

      setClaimedUser(newUser);
    } catch (error) {
      const e = error as AxiosError;
      setFormError(e.response?.data?.message);
    }
  };

  const renderError = () => {
    return (
      <Div mv='m'>
        <Title level={3}>Invalid Link</Title>
        <Paragraph>
          The link seems to be invalid or expired. If you already have an
          account, proceed to <Link to={HIRER_ROUTES.LOGIN}>login</Link>.
        </Paragraph>
        <Paragraph>
          No registered yet?{' '}
          <Link to={HIRER_ROUTES.REGISTER}>Create a hirer account</Link>
        </Paragraph>
      </Div>
    );
  };

  const renderForm = () => {
    return (
      <Div>
        <Title level={3}>Claim hirer profile</Title>
        <Paragraph>
          You are about to claim hirer profile with id{' '}
          <Text className='bold'>{hirerId}</Text>. If you are not intended to
          claim this profile, leave this page immediately.
        </Paragraph>
        <Paragraph>
          By claiming profile, you would link the said profile with your email.
          Please enter the email you would like link this profile with.
        </Paragraph>

        {recentProjects.length > 0 && (
          <>
            <Title level={5}>These are your recent projects</Title>
            <Paragraph>
              <ul>
                {recentProjects.map((proj) => (
                  <li key={proj.id}>{proj.title}</li>
                ))}
              </ul>
            </Paragraph>
          </>
        )}

        <UserInfo onFinish={onFinish} formError={formError} />
      </Div>
    );
  };

  const renderEmailSentFeedback = () => {
    return (
      <>
        <Div pt='xxl'>
          <Title level={2} className='text-center'>
            <Div>
              <MailOutlined />
            </Div>
            Check your email!
          </Title>
        </Div>

        <Div className='text-center' pb='xl'>
          <Paragraph>
            We have sent an email with a link to {claimedUser?.email} to
            complete your claim profile process. You can now safely close this
            tab and continue over there.
          </Paragraph>

          <Paragraph>P.S: The link will expire in 24 hours.</Paragraph>
        </Div>

        <Button
          type='primary'
          size='large'
          target='_blank'
          href={
            claimedUser?.email
              ? getEmailProviderUrl(
                  claimedUser.email,
                  'Complete Your Registration Now'
                )
              : undefined
          }
          block
        >
          Open Email App
        </Button>
      </>
    );
  };

  return (
    <Page className='page-hirer-claim-profile'>
      <Helmet>
        <title>Claim Profile</title>
        <meta name='description' content='Claim Hirer Profile' />
      </Helmet>
      <Div>
        {!showError && hirerId && code && !claimedUser && renderForm()}
        {!showError && claimedUser && renderEmailSentFeedback()}
        {showError && renderError()}
      </Div>
    </Page>
  );
};

export default ClaimProfilePage;
