import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Div } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { HIRER_ROUTES } from '../../../route/constant';
import { setRequestPaymentInvoices } from '../../../store/hirer.slice';
import { RootState } from '../../../store/store';
import { useAccountingService } from '../../../service/accounting.service';
import { serviceDomain } from '../../../../config/app.config';
import {
  RequestPaymentInvoice,
  RequestPaymentInvoiceStatus,
} from '../../../helper/type';

import './PaymentFeedback.scss';

const { Title, Paragraph } = Typography;

const PaymentFeedbackPage = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { status } = useParams<{ status: 'successful' | 'unsuccessful' }>();
  const projectId = new URLSearchParams(search).get('projectId') || '';
  const invoiceId = new URLSearchParams(search).get('invoiceId');
  const { authHirer } = useSelector((state: RootState) => state.user);
  const { mappedRequestPaymentInvoice } = useSelector(
    (state: RootState) => state.hirer
  );
  const accountingService = useAccountingService();

  const currentInvoice = mappedRequestPaymentInvoice[projectId];

  useEffect(() => {
    if (status === 'unsuccessful' && !mappedRequestPaymentInvoice[projectId]) {
      fetchHirerInvoices();
    }
  }, []);

  const fetchHirerInvoices = async () => {
    if (!authHirer.hirerId) return;

    const { data } =
      await accountingService.listRequestPaymentInvoicesByPayerId(
        authHirer.hirerId
      );
    dispatch(setRequestPaymentInvoices(data));
  };

  const handleRetryPayment = async () => {
    if (!currentInvoice.id) return;

    let updatedInvoice: RequestPaymentInvoice = currentInvoice;
    if (
      updatedInvoice.id &&
      updatedInvoice.status ===
        RequestPaymentInvoiceStatus.PENDING_PAYER_MAKE_PAYMENT
    ) {
      updatedInvoice = await accountingService.proceedPayment(
        currentInvoice.id,
        {
          success_redirect_url: `${serviceDomain.ui}/hirer/payment/successful`,
          failure_redirect_url: `${serviceDomain.ui}/hirer/payment/unsuccessful?projectId=${projectId}&invoiceId=${invoiceId}`,
        }
      );
    }

    if (updatedInvoice.paymentGatewayInvoiceUrl)
      window.location.href = updatedInvoice.paymentGatewayInvoiceUrl;
  };

  return (
    <Div className='page-hirer-payment-feedback'>
      <Helmet>
        <title>Payment {status} - HeyCast.Me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Div pt='xxl'>
        <Title level={2} className='text-center'>
          <Div>
            {status === 'successful' ? (
              <CheckCircleFilled className='text-primary' />
            ) : (
              <CloseCircleOutlined className='text-danger' />
            )}
          </Div>
          Payment {status === 'successful' ? `${status}!` : status}
        </Title>
      </Div>

      <Div className='text-center' pb='xl'>
        {status === 'successful' ? (
          <Paragraph>
            Thank you for using HeyCast.me. We look forward to working with you
            again!
          </Paragraph>
        ) : (
          <Paragraph>
            Sorry, your payment was not successful. Please try again. If the
            issue persists, contact us at hello@heycast.me for assistance. Thank
            you.
          </Paragraph>
        )}
      </Div>

      {status === 'successful' ? (
        <Button block solid to={HIRER_ROUTES.DASHBOARD}>
          View My Projects
        </Button>
      ) : (
        <Div>
          <Button
            block
            solid
            style={{ marginBottom: '1rem' }}
            onClick={handleRetryPayment}
          >
            Retry Payment
          </Button>
          <Button ghost block to={`${HIRER_ROUTES.DASHBOARD}?tab=unpaid`}>
            Back to My Projects
          </Button>
        </Div>
      )}
    </Div>
  );
};

export default PaymentFeedbackPage;
