import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { animateScroll } from 'react-scroll';
import {
  Typography,
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  Breadcrumb,
  Spin,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as info from '../../../../constant/personalInfo';
import * as skill from '../../../../constant/skill';
import { Div, Page } from '../../../framework';
import { projectAllowance } from '../../../helper/constants';
import { useResetScroll } from '../../../helper/hook';
import { convertTime } from '../../../helper/object';
import { Project, Role } from '../../../helper/type';
import { HIRER_ROUTES } from '../../../route/constant';
import {
  setRole as setMappedRoles,
  setProject as setMappedProjects,
} from '../../../store/hirer.slice';
import { useJobService } from '../../../service/job.service';
import { openModal } from '../../../store/app.slice';
import { RootState } from '../../../store/store';
import AllowanceFormItem from './AllowanceFormItem';
import { featureToggle } from '../../../../config/app.config';

import './Form.scss';

const { Option, OptGroup } = Select;
const { Title } = Typography;

const FormPage = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const history = useHistory();
  const { state: locationState = {} } = useLocation<any>();
  const jobService = useJobService();
  const [form] = Form.useForm();

  const { projectId, roleId } = useParams<{
    projectId: string;
    roleId: string;
  }>();
  const { authHirer } = useSelector((state: RootState) => state.user);
  const { mappedProjects, mappedRoles } = useSelector(
    (state: RootState) => state.hirer
  );
  const [isEdit] = useState<boolean>(roleId ? true : false);
  const [formLoading, setFormLoading] = useState(false);

  const [project, setProject] = useState<Project>();
  const [role, setRole] = useState<Role>();
  const [tempSavedRoles, setTempSavedRoles] = useState<Role[]>([]);

  const [shootDates, setShootDates] = useState<string[]>([]);

  useEffect(() => {
    const { savedProject } = locationState;
    if (authHirer.hirerId) return;

    if (!savedProject) {
      alert('Unable to find project details, please try again.');
      history.replace('/hirer/project');
    } else {
      setProject(savedProject);
    }
  }, []);

  useEffect(() => {
    if (!authHirer.hirerId) return;

    (async () => {
      setFormLoading(true);

      if (!mappedProjects[projectId]) {
        const result = await jobService.getProject(projectId);
        dispatch(setMappedProjects(result));
      } else {
        setProject(mappedProjects[projectId]);
      }

      if (roleId) {
        if (!mappedRoles[projectId] || !mappedRoles[projectId][roleId]) {
          const result = await jobService.getRole(roleId);
          dispatch(setMappedRoles({ projectId, role: result }));
        } else {
          setRole(mappedRoles[projectId][roleId]);
        }
      }
      setFormLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (mappedRoles[projectId] && mappedRoles[projectId][roleId]) {
      setRole(mappedRoles[projectId][roleId]);
    }
  }, [mappedRoles]);

  useEffect(() => {
    if (authHirer.hirerId) {
      setProject(mappedProjects[projectId]);
    }
  }, [mappedProjects]);

  useEffect(() => {
    if (project) {
      if (project.isNotConsecutiveDate) {
        setShootDates(project.shootDates ?? []);
      } else {
        const { shootDate } = project;
        const dates: string[] = [];
        if (shootDate && shootDate.from && shootDate.to) {
          const from = moment(shootDate.from);
          const to = moment(shootDate.to);
          do {
            dates.push(from.toISOString());
            from.add(1, 'day');
          } while (from.isSameOrBefore(to));
          setShootDates(dates);
        }
      }
    }
  }, [project]);

  useEffect(() => {
    if (role) {
      const { race, ethnicity, ...values } = role;
      form.setFieldsValue({
        ...values,
        raceEthnicity: role.raceEthnicity ?? [race, ethnicity?.toLowerCase()],
      });
    }
  }, [role]);

  const getRoleValues = ({
    languages,
    shootDates,
    raceEthnicity,
    allowances,
    ...values
  }: any) => {
    return {
      projectId,
      ...values,
      languages: languages || [],
      allowances: allowances || [],
      ...(shootDates && {
        shootDates: shootDates
          .map((d: any) => convertTime(d))
          .sort((a: string, b: string) => a.localeCompare(b)),
      }),
      raceEthnicity: raceEthnicity || [],
    };
  };

  const handleFormSubmitWithoutLogin = async (isAddAnotherRole: boolean) => {
    const values = await form.validateFields();
    const roleValues = getRoleValues(values);
    const savedRoles = [...tempSavedRoles, roleValues];

    if (isAddAnotherRole) {
      form.resetFields();
      animateScroll.scrollToTop({
        duration: 0,
      });

      setTempSavedRoles(savedRoles);
    } else {
      history.push(HIRER_ROUTES.REGISTER, {
        ...locationState,
        savedRoles,
      });
    }
  };

  const handleFormSubmit = async (isAddAnotherRole: boolean) => {
    const values = await form.validateFields();
    const roleValues = getRoleValues(values);
    let role;
    if (roleId) {
      role = await jobService.updateRole(roleValues, roleId);
      role.id = roleId;
    } else {
      role = await jobService.createRole(roleValues);
    }
    dispatch(setMappedRoles({ projectId, role }));

    if (isAddAnotherRole) {
      form.resetFields();
      animateScroll.scrollToTop({
        duration: 0,
      });
    } else {
      history.push(`/hirer/project/${projectId}`);
    }
  };

  const onDelete = async () => {
    dispatch(
      openModal({
        modal: 'delete-role',
        config: {
          context: { role },
        },
      })
    );
  };

  return (
    <Page className='page-hirer-form'>
      {authHirer.hirerId && (
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to='/hirer/home'>All Projects</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/hirer/project/${projectId}`}>{project?.title}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{role?.name || 'New Role'}</Breadcrumb.Item>
        </Breadcrumb>
      )}

      <Spin spinning={formLoading}>
        <Form form={form} layout='vertical'>
          <Title level={2}>{isEdit ? 'Edit' : 'Create'} Role</Title>

          <Title level={3}>Details</Title>
          <Form.Item
            name='name'
            label='Name for this Role'
            rules={[
              {
                required: true,
                message: 'Please input a name for this role',
              },
            ]}
          >
            <Input placeholder='Mother/Doctor/Young Adult/Chinese Male/Teacher/ Office worker/Background' />
          </Form.Item>
          <Form.Item
            name='type'
            label='Type'
            rules={[
              {
                required: true,
                message: 'Please choose role type',
              },
            ]}
          >
            <Select placeholder='Role type'>
              <Option value='main'>Main</Option>
              <Option value='featured'>Featured</Option>
              <Option value='featured extra'>Featured Extra</Option>
              <Option value='background'>Background</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name='description'
            label='Description'
            rules={[
              {
                required: true,
                message: 'Please provide description about this role.',
              },
            ]}
          >
            <Input.TextArea
              placeholder='Presentable and good looking/ Fluent in English/ Looking smart and mature/ Can do k-pop dance'
              showCount
              autoSize={{
                minRows: 3,
                maxRows: 4,
              }}
            />
          </Form.Item>

          <Form.Item
            name='raceEthnicity'
            label='Race/Ethnicity'
            rules={[
              {
                required: true,
                message: 'Please select your race/ethnicity',
              },
            ]}
          >
            <Select mode='multiple' placeholder='Choose your race/ethnicity'>
              <OptGroup label='Race'>
                {[...info.race, 'Any Race'].map((value, index) => (
                  <Option key={`race-${index}`} value={value.toLowerCase()}>
                    {value}
                  </Option>
                ))}
              </OptGroup>
              <OptGroup label='Ethnicity'>
                {[
                  ...info.ethnicity.filter(
                    (eth) =>
                      ![
                        'Asian - Chinese',
                        'Asian - Indian',
                        'Asian - Malay',
                        'Asian - Others',
                        'Asian - Southeast',
                        'Mixed',
                      ].includes(eth)
                  ),
                  'Any Ethnicity',
                ].map((value, index) => (
                  <Option
                    key={`ethnicity-${index}`}
                    value={value.toLowerCase()}
                  >
                    {value}
                  </Option>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>

          <Form.Item
            name='gender'
            label='Gender'
            rules={[
              {
                required: true,
                message: 'Please select your gender',
              },
            ]}
          >
            <Select placeholder='Choose your gender'>
              {info.gender.map((value) => (
                <Option key={value.key} value={value.name.toLowerCase()}>
                  {value.name}
                </Option>
              ))}
              <Option value='any'>Any Gender</Option>
            </Select>
          </Form.Item>

          <Div flex>
            <Form.Item
              label='Role Age (From)'
              name='ageFrom'
              rules={[
                {
                  required: true,
                  message: 'Please input visual age (from)',
                },
                {
                  validator: async (_, value) => {
                    if (value) {
                      const ageTo = form.getFieldValue('ageTo');
                      if (value > ageTo) {
                        return Promise.reject(
                          new Error(
                            'Age(from) must be lesser or equal to age(to).'
                          )
                        );
                      }
                    }
                  },
                },
              ]}
            >
              <InputNumber
                min={0}
                max={120}
                placeholder='from'
                addonAfter='years old'
              />
            </Form.Item>
            <Div ph='s'></Div>
            <Form.Item
              label='Role Age (To)'
              name='ageTo'
              rules={[
                {
                  required: true,
                  message: 'Please input visual age (to)',
                },
                {
                  validator: async (_, value) => {
                    if (value) {
                      const ageFrom = form.getFieldValue('ageFrom');
                      if (value < ageFrom) {
                        return Promise.reject(
                          new Error(
                            'Age(to) must be greater or equal to age(from).'
                          )
                        );
                      }
                    }
                  },
                },
              ]}
            >
              <InputNumber
                min={0}
                max={120}
                placeholder='to'
                addonAfter='years old'
              />
            </Form.Item>
          </Div>

          <Form.Item name='languages' label='Languages'>
            <Select mode='multiple' placeholder='Choose your languages'>
              <OptGroup label='Standard'>
                {skill.languages.standard.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </OptGroup>
              <OptGroup label='Chinese'>
                {skill.languages.chinese.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </OptGroup>
              <OptGroup label='Indian'>
                {skill.languages.indian.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </OptGroup>
              <OptGroup label='Malay'>
                {skill.languages.malay.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </OptGroup>
              <OptGroup label='Native'>
                {skill.languages.native.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </OptGroup>
              <OptGroup label='Foreign'>
                {skill.languages.foreign.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>

          <Form.Item
            label='Talent Fee per talent'
            name='payment'
            dependencies={['type']}
            validateTrigger='onBlur'
            rules={[
              {
                required: true,
                message: 'Please input talent fee for this role.',
              },
              {
                validator: async (_, value) => {
                  if (value != undefined && !isNaN(value)) {
                    const roleType = form.getFieldValue('type');
                    const minFee = roleType === 'background' ? 120 : 200;
                    if (value < minFee) {
                      return Promise.reject(
                        new Error(
                          `The minimum talent fee for a role is RM${minFee} to ensure fair compensation and attract the suitable talent.`
                        )
                      );
                    }
                  }
                },
              },
            ]}
          >
            <InputNumber min={0} placeholder='1000' addonBefore={'RM'} />
          </Form.Item>

          {featureToggle.invoice && (
            <>
              <Div pt='xl' mb='m'>
                Talent Allowance
              </Div>
              <Form.List
                name='allowances'
                rules={[
                  {
                    validator: async (_, listItems) => {
                      if (!listItems || listItems.length === 0) return;
                      const types: Record<string, number> = {};
                      listItems
                        .filter((item: unknown) => Boolean(item))
                        .forEach((item: any) => {
                          if (types[item.allowanceType])
                            throw new Error(
                              `Duplicate Type of Allowance: ${item.allowanceType}`
                            );
                          types[item.allowanceType] = 1;
                        });
                    },
                  },
                ]}
              >
                {(items, { add, remove }, { errors }) => (
                  <>
                    {items.map((item) => (
                      <AllowanceFormItem
                        key={item.key}
                        form={form}
                        item={item}
                        remove={() => remove(item.name)}
                      />
                    ))}
                    <Form.Item>
                      <Button
                        type='dashed'
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        disabled={items.length >= projectAllowance.length}
                      >
                        Add Allowance
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </>
          )}

          <Form.Item
            label='Talent Needed'
            name='countNeeded'
            rules={[
              {
                required: true,
                message: 'Please input number of talent needed',
              },
            ]}
          >
            <InputNumber min={1} />
          </Form.Item>

          <Form.Item
            label='Shoot Date(s) for this role'
            name='shootDates'
            rules={[
              {
                required: true,
                message: 'Please select shoot date(s) for this role.',
              },
            ]}
          >
            <Select mode='multiple'>
              {shootDates.map((date) => (
                <Option key={date}>{moment(date).format('DD MMM YYYY')}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            {!authHirer.hirerId ? (
              <>
                <Button
                  type='primary'
                  block
                  onClick={() => handleFormSubmitWithoutLogin(true)}
                >
                  Create Project and Add New Role
                </Button>
                <Button
                  block
                  onClick={() => handleFormSubmitWithoutLogin(false)}
                  style={{ marginTop: '1rem' }}
                >
                  Create Project
                </Button>
              </>
            ) : isEdit ? (
              <>
                <Button
                  type='primary'
                  block
                  onClick={() => handleFormSubmit(false)}
                >
                  Save
                </Button>
                <Button
                  danger
                  className='btn-delete'
                  htmlType='button'
                  onClick={onDelete}
                  block
                >
                  Delete
                </Button>
              </>
            ) : (
              <>
                <Button
                  type='primary'
                  block
                  onClick={() => handleFormSubmit(true)}
                >
                  Add Another Role
                </Button>
                <Button
                  block
                  onClick={() => handleFormSubmit(false)}
                  style={{ marginTop: '1rem' }}
                >
                  Create Role and Done
                </Button>
              </>
            )}
          </Form.Item>
        </Form>
      </Spin>
    </Page>
  );
};

export default FormPage;
