import React, { FC } from 'react';
import { Typography, Tag } from 'antd';
import { format } from 'date-fns';
import { supportEmail } from '../../../config/app.config';
import { Button, Div } from '../../framework';
import { getComposeEmailUrl } from '../../helper/getComposeEmailUrl';
import { roundDecimalPlace } from '../../helper/object';
import {
  Project,
  RequestPaymentInvoice,
  RequestPaymentInvoiceStatus,
  Role,
  SelfBilledInvoice,
  SelfBilledInvoiceStatus,
} from '../../helper/type';
import TalentInvoiceItems from './TalentInvoiceItems';
import TalentPaymentDetails from './TalentPaymentDetails';
import HirerInvoiceItems from './HirerInvoiceItems';

import './InvoiceDetails.scss';

const { Title } = Typography;

type Props = {
  roles?: Role[];
  project: Project;
  onSubmitInvoice: () => void;
} & (
  | { type: 'talent'; invoice: SelfBilledInvoice }
  | { type: 'hirer'; invoice: RequestPaymentInvoice }
);

const InvoiceDetails: FC<Props> = ({
  roles,
  project,
  type,
  invoice,
  onSubmitInvoice,
}) => {
  const renderInvoiceStatus = () => {
    if (invoice.status === RequestPaymentInvoiceStatus.PAYMENT_RECEIVED) {
      return (
        <Tag color='#16B55E' className='rounded'>
          Paid
        </Tag>
      );
    }

    const statusText: Record<string, string> = {
      [RequestPaymentInvoiceStatus.DRAFT]: 'Draft',
      [RequestPaymentInvoiceStatus.PENDING_PAYER_REVIEW]: 'Pending Review',
      [RequestPaymentInvoiceStatus.PENDING_PAYER_MAKE_PAYMENT]:
        'Pending Payment',
      [RequestPaymentInvoiceStatus.PENDING_PAYMENT_GATEWAY_WEBHOOK]:
        'Pending Payment',
    };
    if (statusText[invoice.status ?? '']) {
      return (
        <Tag color='#444444' className='rounded'>
          {statusText[invoice.status ?? '']}
        </Tag>
      );
    }
  };

  const renderInvoiceTotalAmount = () => {
    let total = 0;
    invoice.items?.forEach((item) => (total += item.amount));
    return roundDecimalPlace(total, 2).toFixed(2);
  };

  return (
    <Div className='div-invoice-details'>
      <Div flex className='title-container'>
        <Div>
          <Title level={4} className='title'>
            {invoice.id}
          </Title>
          {type === 'hirer' && renderInvoiceStatus()}
        </Div>
        <a
          href={getComposeEmailUrl({
            recipient: supportEmail,
            subject: `Report invoice issue - ${invoice.id}`,
          })}
          className='btn-report-issue'
        >
          Report issue
        </a>
      </Div>

      <Div>
        <Div mb='xl'>
          <Div className='bold'>Invoice Date</Div>
          <Div>
            {invoice.createdAt &&
              format(new Date(invoice.createdAt), 'dd MMM yyyy')}
          </Div>
        </Div>

        <Div mb='xl'>
          <Div className='bold'>Talents Booked for</Div>
          <Div>{project.title}</Div>
        </Div>
      </Div>

      <hr />

      <Div flex mt='xl' mb='l' className='justify-between'>
        <span className='bold'>Details</span>
        <span className='bold'>Amount</span>
      </Div>

      {type === 'hirer' && (
        <>
          <HirerInvoiceItems invoice={invoice} />
          <Div mv='xl'>
            <hr />
          </Div>
        </>
      )}

      {type === 'talent' && roles && (
        <TalentInvoiceItems invoice={invoice} project={project} roles={roles} />
      )}

      <Div flex mt='xl' mb='l' className='justify-between'>
        <span className='bold'>Total</span>
        <span className='bold'>MYR {renderInvoiceTotalAmount()}</span>
      </Div>

      <hr />

      {type === 'talent' && (
        <>
          <TalentPaymentDetails invoice={invoice} />
          <hr />
        </>
      )}

      <Div mv='xl'>
        {type === 'hirer' &&
          invoice.status !== RequestPaymentInvoiceStatus.PAYMENT_RECEIVED && (
            <Button
              block
              solid
              disabled={invoice.status === RequestPaymentInvoiceStatus.DRAFT}
              onClick={onSubmitInvoice}
            >
              {invoice.status ===
              RequestPaymentInvoiceStatus.PENDING_PAYER_REVIEW
                ? 'Save & Make Payment'
                : 'Make Payment'}
            </Button>
          )}
        {type === 'talent' &&
          invoice.status === SelfBilledInvoiceStatus.DRAFT && (
            <Button block solid onClick={onSubmitInvoice}>
              Confirm Invoice Details
            </Button>
          )}
      </Div>
    </Div>
  );
};

export default InvoiceDetails;
