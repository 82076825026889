import { Button, Card, Typography } from 'antd';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HirerUserCard from '../../../component/Card/HirerUserCard/HirerUserCard';
import UserInfo, {
  UserInfoValues,
} from '../../../component/HirerForm/UserInfo';
import { ModalContext } from '../../../component/Modal/DeleteHirerUserModal';
import { Div } from '../../../framework';
import { HIRER_ROUTES } from '../../../route/constant';
import { useHirerService } from '../../../service/hirer.service';
import { openModal } from '../../../store/app.slice';
import { setAuthHirer } from '../../../store/user.slice';
import { setCompanyUser, setCompanyUsers } from '../../../store/hirer.slice';
import { RootState } from '../../../store/store';

import './HirerUsersTab.scss';

const { Title } = Typography;

const HirerUsersTab: FC = () => {
  const dispatch = useDispatch();
  const { authHirer } = useSelector((state: RootState) => state.user);
  const { companyUsers } = useSelector((state: RootState) => state.hirer);
  const history = useHistory();
  const hirerService = useHirerService();

  useEffect(() => {
    (async () => {
      if (companyUsers.length === 0) {
        const users = await hirerService.listHirerUsers(authHirer.hirerId);
        dispatch(setCompanyUsers(users));
      }
    })();
  }, []);

  const handleAuthHirerUpdate = async (values: UserInfoValues) => {
    const { hirerId, userId } = authHirer;
    const updatedUser = await hirerService.updateUser({
      hirerId,
      userId,
      name: values.name,
      ...values.contactNumber,
    });
    dispatch(setAuthHirer(updatedUser));
    dispatch(setCompanyUser(updatedUser));
  };

  const handleDeleteUser = async (userId: string) => {
    const context: ModalContext = {
      hirerId: authHirer.hirerId,
      userId,
    };
    dispatch(
      openModal({
        modal: 'delete-hirer-user',
        config: { context },
      })
    );
  };

  return (
    <Div className='hirer-users-tab'>
      <Title level={4} style={{ margin: '0.5rem 0' }}>
        Total Users: {companyUsers.length}
      </Title>
      <Button
        type='primary'
        onClick={() => history.push(HIRER_ROUTES.NEW_USER)}
      >
        Add New User
      </Button>

      <Card className='my-details-card'>
        <Title level={5}>My Details</Title>
        <UserInfo
          onFinish={handleAuthHirerUpdate}
          disabledEmail
          submitBtnText='Save Changes'
          values={{
            email: authHirer.email,
            name: authHirer.name,
            contactNumber: {
              phoneNumber: authHirer.phoneNumber,
              phoneNumberPrefix: authHirer.phoneNumberPrefix,
            },
          }}
        />
      </Card>

      {companyUsers
        .filter((user) => user.userId !== authHirer.userId)
        .map((user) => (
          <Div key={user.userId} pt='l'>
            <HirerUserCard
              user={user}
              onDelete={() => handleDeleteUser(user.userId)}
            />
          </Div>
        ))}
    </Div>
  );
};

export default HirerUsersTab;
