import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Breadcrumb, Empty } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { format, parseISO } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { Button, Div } from '../../framework';
import { RootState } from '../../store/store';
import { Project, Role } from '../../helper/type';
import RoleCard from '../../component/Card/RoleCard/RoleCard';
import ShareProjectDrawer from '../../component/Drawer/ShareProjectDrawer/ShareProjectDrawer';
import ProjectActionDropdown from '../../component/Dropdown/ProjectActionDropdown';
import SkeletonRoleCard from '../../component/Skeleton/RoleCard';
import { useJobService } from '../../service/job.service';
import { setRoles as setGlobalRoles } from '../../store/hirer.slice';
import { useResetScroll } from '../../helper/hook';
import sortRolesForHirer from '../../helper/job/sortRolesForHirer';

import './Roles.scss';

const { Title, Paragraph } = Typography;

const RolesPage = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const jobService = useJobService();
  const { mappedProjects, mappedRoles } = useSelector(
    (state: RootState) => state.hirer
  );
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<Project>(
    mappedProjects[projectId] || {}
  );
  const [roles, setRoles] = useState<Record<string, Role>>({});
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await jobService.getRolesByProject(projectId);
      dispatch(setGlobalRoles({ projectId, roles: data }));
    })();
  }, []);

  useEffect(() => {
    if (mappedRoles[projectId]) {
      setRoles(mappedRoles[projectId]);
    }
  }, [mappedRoles]);

  useEffect(() => {
    setProject(mappedProjects[projectId]);
  }, [mappedProjects]);

  const handleShareProject = () => {
    if (project.closingDate > new Date().toISOString()) {
      setShowDrawer(true);
    }
  };

  const renderShootingDate = (project: Project) => {
    if (project.isNotConsecutiveDate) {
      return (
        <>
          {project.shootDates && project.shootDates.length > 0
            ? project.shootDates.map((date) => {
                return (
                  <Div key={date}>{format(parseISO(date), 'dd MMM yyy')}</Div>
                );
              })
            : 'TBC'}
        </>
      );
    }

    return (
      <>
        {project.shootDate ? (
          <>
            <Div>
              <small>FROM</small>
              {format(new Date(project.shootDate.from), 'dd MMM yyyy')}
            </Div>
            <Div>
              <small>TO</small>
              {format(new Date(project.shootDate.to), 'dd MMM yyyy')}
            </Div>
          </>
        ) : (
          'TBC'
        )}
      </>
    );
  };

  const renderProjectDetails = () => {
    return project ? (
      <Div className='project'>
        <Div flex className='title-container'>
          <Title level={4}>{project.title}</Title>
          <Div noGrow className='button-container'>
            <ProjectActionDropdown
              project={project}
              handleShare={handleShareProject}
            >
              <Button className='more-action-btn'>
                <MoreOutlined />
              </Button>
            </ProjectActionDropdown>
          </Div>
        </Div>

        {project.description &&
          project.description.split('\n').map((d, index) => (
            <Paragraph key={`${index}${d}`} className='description'>
              <Paragraph className='description'>{d}</Paragraph>
            </Paragraph>
          ))}
        <Div className='details'>
          <Div flex className='attribute'>
            <Div className='label'>SHOOT LOCATION</Div>
            <Div className='value'>{project.shootLocation}</Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label date'>SHOOT DATE</Div>
            <Div className='value'>{renderShootingDate(project)}</Div>
          </Div>
          {project.media ? (
            <Div flex className='attribute'>
              <Div className='label'>MEDIA</Div>
              <Div className='value'>{project.media?.join(', ')}</Div>
            </Div>
          ) : null}
          {project.contractual ? (
            <Div flex className='attribute'>
              <Div className='label'>CONTRACTUAL</Div>
              <Div className='value'>{project.contractual ? 'Yes' : 'No'}</Div>
            </Div>
          ) : null}
          {project.mediaUsageCountries ? (
            <Div flex className='attribute'>
              <Div className='label'>MEDIA USAGE</Div>
              <Div className='value'>
                {project.mediaUsageCountries?.join(', ')}
              </Div>
            </Div>
          ) : null}
          {project.usageDuration ? (
            <Div flex className='attribute'>
              <Div className='label'>USAGE DURATION</Div>
              <Div className='value'>
                {project.usageDuration?.perpetual
                  ? 'Perpetual'
                  : project.usageDuration?.duration}
              </Div>
            </Div>
          ) : null}
          {project.paymentTerm ? (
            <Div flex className='attribute'>
              <Div className='label'>PAYMENT TERM</Div>
              <Div className='value'>
                {project.paymentTerm?.value} {project.paymentTerm?.period}
                {project.paymentTerm?.value > 1 ? 's' : ''}
              </Div>
            </Div>
          ) : null}
          {project.allowances ? (
            <Div flex className='attribute'>
              <Div className='label'>ALLOWANCES</Div>
              <Div className='value'>
                {project.allowances?.length
                  ? project.allowances?.join(', ')
                  : 'N/A'}
              </Div>
            </Div>
          ) : null}
          {project.closingDate ? (
            <Div flex className='attribute'>
              <Div className='label'>CLOSING DATE</Div>
              <Div className='value'>
                {format(new Date(project.closingDate), 'dd MMM yyyy')}
              </Div>
            </Div>
          ) : null}
        </Div>
      </Div>
    ) : (
      <Div className='project'>
        <Title level={4}>
          <Skeleton width={100} height={20} />
        </Title>
        <Paragraph className='description'>
          <Skeleton width={270} height={10} />
          <Skeleton width={240} height={10} />
        </Paragraph>
        <Div className='details'>
          <Div flex className='attribute'>
            <Div className='label'>
              <Skeleton width={100} height={10} />
            </Div>
            <Div className='value'>
              <Skeleton width={200} height={20} />
            </Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label date'>
              <Skeleton width={70} height={10} />
            </Div>
            <Div className='value'>
              <Div>
                <Skeleton width={120} height={20} />
              </Div>
              <Div>
                <Skeleton width={100} height={20} />
              </Div>
            </Div>
          </Div>
          <Div flex className='attribute'>
            <Div className='label'>
              <Skeleton width={80} height={10} />
            </Div>
            <Div className='value'>
              <Skeleton width={100} height={20} />
            </Div>
          </Div>
        </Div>
      </Div>
    );
  };

  return (
    <Div className='page-hirer-roles'>
      <Helmet>
        <title>{`${project?.title || 'Project'}`} - HeyCast.Me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='/hirer/home'>All Projects</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>{project?.title || 'Project'}</Breadcrumb.Item>
      </Breadcrumb>
      {renderProjectDetails()}
      <Div className='role-list'>
        <SkeletonRoleCard>
          {project?.id && Object.values(roles).length ? (
            sortRolesForHirer
              .apply(null, [Object.values(roles)])
              .map((role) => (
                <RoleCard key={role.id} role={role} project={project} />
              ))
          ) : (
            <Div className='zero-state'>
              <Empty description='No roles' />
            </Div>
          )}
        </SkeletonRoleCard>
      </Div>

      {project && (
        <ShareProjectDrawer
          show={showDrawer}
          project={project}
          onClose={() => setShowDrawer(false)}
        />
      )}
    </Div>
  );
};

export default RolesPage;
