import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button, Form } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import UserInfo, {
  UserInfoValues,
} from '../../../component/HirerForm/UserInfo';
import { Div, Page } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { getMilliSeconds } from '../../../helper/object';
import { HirerUser } from '../../../helper/type';
import { HIRER_ROUTES } from '../../../route/constant';
import { useSstAuthService } from '../../../service/auth.sst.service';
import { useHirerService } from '../../../service/hirer.service';
import { setCompanyUser } from '../../../store/hirer.slice';
import { RootState } from '../../../store/store';

import './NewUser.scss';

const { Title, Paragraph } = Typography;

const NewUser = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const { authHirer } = useSelector((state: RootState) => state.user);
  const { companyUsers } = useSelector((state: RootState) => state.hirer);
  const history = useHistory();
  const hirerService = useHirerService();
  const sstAuthService = useSstAuthService();
  const [form] = Form.useForm();

  const [error, setError] = useState('');
  const [duplicateUser, setDuplicateUser] = useState<HirerUser>();
  const [showFeedback, setShowFeedback] = useState<'invited' | 'exists'>();

  const onFinish = async (values: UserInfoValues) => {
    const { name, email, contactNumber } = values;

    const userExists = companyUsers.find((user) => user.email === email);
    if (userExists) {
      setDuplicateUser(userExists);
      setShowFeedback('exists');
      return;
    }

    try {
      const { exists } = await hirerService.emailExists(email);
      if (exists) {
        setError('Email already exists.');
        return;
      }

      const newUser = await hirerService.createUser({
        hirerId: authHirer.hirerId,
        email,
        name,
        ...contactNumber,
      });
      dispatch(setCompanyUser(newUser));

      const { userId, hirerId } = newUser;
      await sstAuthService.getLink({
        authType: 'hirer_register',
        email,
        linkExpiry: getMilliSeconds({ hours: 24 }),
        redirect: `${HIRER_ROUTES.AUTH}/new`,
        data: { email, name, userId, hirerId },
      });

      setShowFeedback('invited');
    } catch (error) {
      const e = error as AxiosError;
      setError(e.response?.data?.message);
    }
  };

  const renderNewUserForm = () => (
    <>
      <Div pt='xxl'>
        <Title level={2} className='text-center'>
          Get more done together — Invite your teammates to collaborate!
        </Title>
      </Div>

      <UserInfo form={form} onFinish={onFinish} formError={error} />
    </>
  );

  const renderFeedback = () => (
    <>
      <Div pt='xxl'>
        {showFeedback === 'invited' ? (
          <Title level={2} className='text-center'>
            <Div>
              <CheckCircleOutlined />
            </Div>
            Invitation sent!
          </Title>
        ) : (
          <Title level={2} className='text-center'>
            User account already exists
          </Title>
        )}
      </Div>

      {showFeedback === 'exists' && (
        <Paragraph className='text-center'>
          We&apos;ve added {duplicateUser?.name} to your company!
        </Paragraph>
      )}

      <Button
        type='primary'
        size='large'
        block
        style={{ marginBottom: '1rem' }}
        onClick={() => history.push(`${HIRER_ROUTES.PROFILE}?tab=users`)}
      >
        View Users
      </Button>
      <Button
        type='default'
        size='large'
        block
        onClick={() => {
          setShowFeedback(undefined);
          setDuplicateUser(undefined);
          form.resetFields();
        }}
      >
        Add Another User
      </Button>
    </>
  );

  return (
    <Page className='page-hirer-new-user'>
      {showFeedback ? renderFeedback() : renderNewUserForm()}
    </Page>
  );
};

export default NewUser;
