import { Button, message, Tag, Typography, Spin } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {
  AppstoreOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  CopyrightOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  FieldTimeOutlined,
  GlobalOutlined,
  TranslationOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { format as formatDateString, parseISO } from 'date-fns';

import { Div } from '../../../framework';
import {
  calcRolePayment,
  getRolePaymentRate,
} from '../../../helper/job/calcRolePayment';
import { Project, Role, Talent } from '../../../helper/type';
import { useJobService } from '../../../service/job.service';
import { useSettingsService } from '../../../service/settings.service';
import sortRolesForTalent from '../../../helper/job/sortRolesForTalent';
import { RolePriority } from '../../../../src/helper/job/matchRoleForTalent';
import { getEmailProviderUrl } from '../../../helper/getEmailProviderUrl';
import { RootState } from '../../../store/store';
import { addApplication } from '../../../store/talent.slice';
import { openModal, setForceDisplayPrompt } from '../../../store/app.slice';
import { setPartialSettings } from '../../../store/user.slice';

import { AUTH_ROUTES } from '../../../route/constant';

import './DirectoryCard.scss';

const { Paragraph, Text, Link } = Typography;

type Props = {
  project: Project;
  preloadRoles?: Role[];
};

const DirectoryCard: FunctionComponent<Props> = ({ project, preloadRoles }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const jobService = useJobService();
  const settingsService = useSettingsService();
  const [roles, setRoles] = useState<
    { role: Role; priority: number; isApplied: boolean }[]
  >([]);
  const [isShowMore, setShowMore] = useState<boolean>(false);
  const { talent, authUser, isTalentOnboarded, settings, impersonator } =
    useSelector((state: RootState) => state.user);
  const { loadStack, isPWA } = useSelector((state: RootState) => state.app);
  const { mappedCurrentApplications } = useSelector(
    (state: RootState) => state.talent
  );

  const { impersonateId, impersonateKey } = impersonator;
  const isImpersonator = impersonateId && impersonateKey;

  const [messageApi, contextHolder] = message.useMessage();
  const isLoadingRoles =
    loadStack.includes(`job/role/list/${project.id}`) && !roles.length;

  const promptEmailVerification = () => {
    const emailProviderUrl = getEmailProviderUrl(
      authUser.email,
      'verify your account'
    );
    const content = (
      <>
        Please{' '}
        {emailProviderUrl !== '' ? (
          <Link href={emailProviderUrl} target='_blank'>
            verify your email
          </Link>
        ) : (
          'verify your email'
        )}{' '}
        before applying for this role
      </>
    );

    messageApi.open({
      type: 'warning',
      content,
    });
  };

  const promptNotOnboarded = () => {
    message.warn({
      content: <>Please complete your profile to apply job</>,
    });
  };

  const promptInstallPWA = () => {
    const content = (
      <>
        <Link href='/talent/prompt-install'>Install our app</Link> to get
        real-time notifications! 😎🔥 Don&apos;t miss out on amazing
        opportunities tailored just for you! 😉
      </>
    );

    messageApi.open({
      type: 'warning',
      content,
      duration: 15,
    });
  };

  const promptEventExpired = () => {
    messageApi.open({
      type: 'warning',
      content: <>This job has already expired</>,
    });
  };

  const promptSubscribeWhatsapp = (projectId: string) => {
    dispatch(
      openModal({
        modal: 'prompt-subscribe-whatsapp',
        config: {
          context: { projectId },
        },
      })
    );
  };

  const handlerApply = async (role: Role) => {
    if (!isImpersonator) {
      if (!authUser.name) {
        sessionStorage.setItem('redirect', location.pathname);
        history.push(AUTH_ROUTES.TALENT_LOGIN);
        return;
      }

      if (!isTalentOnboarded) {
        promptNotOnboarded();
        setTimeout(() => {
          history.push('/talent/profile/contact');
        }, 500);
        return;
      }

      if (!authUser.verified) {
        promptEmailVerification();
        return;
      }

      if (project.closingDate < new Date().toISOString()) {
        promptEventExpired();
        return;
      }

      if (!settings.isWhatsappVerified) {
        const settingsData = await settingsService.getSettings(true);
        dispatch(setPartialSettings(settingsData));

        if (!settingsData.isWhatsappVerified) {
          promptSubscribeWhatsapp(role.projectId);
          return;
        }
      }
    }

    await jobService.applyJob({
      roleId: role.id,
    });
    const { id: roleId, payment } = role;
    const { id: talentId, ...talentWithoutId } = talent;
    dispatch(
      addApplication({
        ...talentWithoutId,
        role,
        project,
        rolePayment: payment,
        appliedAt: new Date().toISOString(),
        talentId: talentId as string,
        roleId: roleId,
      })
    );

    if (!isPWA) {
      dispatch(setForceDisplayPrompt({ isForceDisplay: true }));
      promptInstallPWA();
    }
  };

  useEffect(() => {
    (async () => {
      if (!talent) {
        return;
      }

      const roles =
        preloadRoles ?? (await jobService.getRolesByProject(project.id));
      setRoles(
        sortRolesForTalent(talent as Talent, roles, mappedCurrentApplications)
      );
    })();
  }, [talent, project, mappedCurrentApplications, preloadRoles]);

  const renderRoleSkeleton = () => {
    return (
      <Div>
        <Div flex p='s' pl='l' pr='l' className='role-title'>
          <Skeleton
            width={150}
            height={20}
            baseColor='#fff3'
            highlightColor='#ebebeb33'
          />
        </Div>
        <Div p='xs' pl='l' pr='l' className='role-details'>
          <Div pb='xs' className='role-requirements'>
            <Skeleton width={200} height={10} />
          </Div>
          <Div pb='xs' className='capitalize'>
            <TranslationOutlined className='icon' />
            <Skeleton width={70} height={10} />
          </Div>
          <Div pb='xs'>
            <DollarOutlined className='icon' />
            <Skeleton width={150} height={10} />
          </Div>
          <Div pb='xs' className='role-description'>
            <Skeleton width={270} height={10} />
          </Div>
        </Div>
      </Div>
    );
  };

  const renderApplyButton = (
    role: Role,
    priority: number,
    isApplied: boolean
  ) => {
    const isApplying = loadStack.includes(`job/apply/${role.id}`);
    const isLoadingApplications =
      loadStack.filter((stack) => stack.startsWith('job/application/list'))
        .length > 0;

    let btnText;
    const isDisabled =
      (isTalentOnboarded &&
        authUser.name &&
        priority === RolePriority.NOT_SUITABLE) ||
      isApplied ||
      isApplying ||
      isLoadingApplications;

    if (!isTalentOnboarded) {
      btnText = 'Apply';
    } else if (authUser.name && priority === RolePriority.NOT_SUITABLE) {
      btnText = 'Not Suitable';
    } else if (isApplying) {
      btnText = (
        <>
          Applying <Spin size='small' style={{ marginLeft: '4px' }} />
        </>
      );
    } else if (isApplied) {
      btnText = (
        <>
          Applied <CheckCircleOutlined />
        </>
      );
    } else if (isLoadingApplications) {
      btnText = (
        <>
          Loading <Spin size='small' style={{ marginLeft: '4px' }} />
        </>
      );
    } else {
      btnText = 'Apply';
    }

    return (
      <Button
        size='small'
        type='link'
        className='link-button'
        disabled={isDisabled}
        onClick={() => handlerApply(role)}
      >
        {btnText}
      </Button>
    );
  };

  const renderShootingDate = (project: Project) => {
    if (project.isNotConsecutiveDate) {
      return (
        <>
          {project.shootDates && project.shootDates.length > 0
            ? project.shootDates
                .map((date) => formatDateString(parseISO(date), 'dd MMM yyy'))
                .join(', ')
            : 'TBC'}
        </>
      );
    }

    return (
      <>
        {project.shootDate?.from
          ? formatDateString(parseISO(project.shootDate.from), 'dd MMM yyy')
          : 'TBC'}
        {project.shootDate?.to.length >= 0 &&
        project.shootDate?.to !== project.shootDate?.from
          ? ` - ${formatDateString(
              parseISO(project.shootDate.to),
              'dd MMM yyy'
            )}`
          : ''}
      </>
    );
  };

  return (
    <Div mb='xxl' className='directory-card'>
      {contextHolder}
      <Div>
        <Text className='project-title'>{project.title}</Text>

        <Div ph='m' pv='xs' className='project-details'>
          <Div className='project-info bold' pb='xs'>
            <EnvironmentOutlined className='icon' />
            {project.shootLocation || 'TBC'}
          </Div>
          <Div className='project-info bold' pb='xs'>
            <CalendarOutlined className='icon' />

            {renderShootingDate(project)}

            {project.isTentative ? (
              <Tag color='#faad14' className='tentative-tag'>
                TENTATIVE
              </Tag>
            ) : null}
          </Div>
          {isShowMore && (
            <>
              {project.type && (
                <Div className='project-info' pb='xs'>
                  <AppstoreOutlined className='icon' />
                  {project.type}
                </Div>
              )}
              {project.contractual !== undefined && (
                <Div className='project-info' pb='xs'>
                  <CheckSquareOutlined className='icon' />
                  {!project.contractual ? 'Non-Contractual' : 'Contractual'}
                </Div>
              )}
              {project.paymentTerm && (
                <Div className='project-info' pb='xs'>
                  <FieldTimeOutlined className='icon' />
                  {project.paymentTerm?.value} {project.paymentTerm?.period}{' '}
                  payment term
                </Div>
              )}
              {project.media && project.media.length > 0 && (
                <Div className='project-info' pb='xs'>
                  <VideoCameraOutlined className='icon' />
                  {project.media.join(', ')}
                </Div>
              )}

              {project.mediaUsageCountries &&
                project.mediaUsageCountries.length > 0 && (
                  <Div className='project-info' pb='xs'>
                    <GlobalOutlined className='icon' />
                    {project.mediaUsageCountries.join(', ')}
                  </Div>
                )}
              {project.usageDuration &&
                (project.usageDuration.perpetual !== undefined ||
                  project.usageDuration.duration !== undefined) && (
                  <Div className='project-info' pb='xs'>
                    <CopyrightOutlined className='icon' />
                    {project.usageDuration?.perpetual
                      ? 'Perpetual'
                      : project.usageDuration?.duration ?? ''}
                  </Div>
                )}
            </>
          )}
          {/* <Paragraph
            className='project-description'
            style={{
              marginBottom: '0.25rem',
              ...(!project.allowances?.length
                ? {
                    paddingRight: '80px',
                  }
                : {}),
            }}
          >
            {project.description}
          </Paragraph> */}

          {project.description &&
            project.description.split('\n').map((d, index) => (
              <Paragraph
                key={`${index}${d}`}
                className='project-description'
                style={{
                  marginBottom: '0.25rem',
                }}
              >
                {d}
              </Paragraph>
            ))}
          {project.allowances?.length > 0 && (
            <Div
              className='project-description allowance bold'
              style={{ paddingRight: '80px' }}
            >{`With ${project.allowances.join(', ')} allowance(s)`}</Div>
          )}
          <Button
            onClick={() => setShowMore((flag) => !flag)}
            size='small'
            type='link'
            className='show-more-link-button'
          >
            {!isShowMore ? 'Show More' : 'Show Less'}
          </Button>
        </Div>
      </Div>
      <Div className='project-roles'>
        {isLoadingRoles ? renderRoleSkeleton() : null}
        {!isLoadingRoles &&
          roles.map(({ role, priority, isApplied }) => (
            <div key={role.id}>
              <Div
                key={`${role.id}-title`}
                flex
                p='s'
                pl='l'
                pr='l'
                className='role-title'
              >
                <Text>
                  {isTalentOnboarded &&
                    priority === RolePriority.RECOMMENDED && (
                      <Tag color='#87d068' className='recommend-tag'>
                        RECOMMENDED
                      </Tag>
                    )}
                  {role.name}{' '}
                </Text>

                {renderApplyButton(role, priority, isApplied)}
              </Div>
              <Div
                key={`${role.id}-details`}
                p='xs'
                pl='l'
                pr='l'
                className='role-details'
              >
                <Div pb='xs' className='role-requirements'>
                  <span className='capitalize bold'>{role.type}</span>:{' '}
                  <span className='capitalize'>{role.gender}</span>,{' '}
                  <span className='capitalize'>
                    {role.raceEthnicity?.join(', ') ??
                      (role.ethnicity === 'Caucasian'
                        ? 'Caucasian'
                        : role.race)}
                  </span>
                  , {role.ageFrom}-{role.ageTo} years old
                </Div>

                {role.shootDates && role.shootDates.length > 0 && (
                  <Div pb='xs' className='capitalize bold'>
                    <CalendarOutlined className='icon' />
                    {role.shootDates
                      ?.map((date) =>
                        formatDateString(parseISO(date), 'dd MMM yyy')
                      )
                      .join(', ')}
                  </Div>
                )}

                {role.languages?.length > 0 && (
                  <Div pb='xs' className='capitalize'>
                    <TranslationOutlined className='icon' />
                    {role.languages?.join(', ')}
                  </Div>
                )}
                <Div pb='xs'>
                  <DollarOutlined className='icon' />
                  {role.payment ? (
                    <>
                      <span className='bold'>
                        RM {calcRolePayment(role.payment)}
                      </span>
                      ,{' '}
                      <span className='role-description'>
                        nett after {getRolePaymentRate(role.payment) * 100}%
                        platform fee
                      </span>
                    </>
                  ) : (
                    <span className='bold'>TBC</span>
                  )}
                </Div>
                <Div pb='xs' className='role-description'>
                  {role.description &&
                    role.description.split('\n').map((d, index) => (
                      <Paragraph
                        key={`${index}${d}`}
                        style={{ marginBottom: '0' }}
                      >
                        {d}
                      </Paragraph>
                    ))}
                </Div>
              </Div>
            </div>
          ))}
      </Div>
    </Div>
  );
};

export default DirectoryCard;
