import { Empty } from 'antd';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { differenceInMinutes } from 'date-fns';
import { useJobService } from '../../../service/job.service';
import { Div, Page, Button } from '../../../framework';
import { Project, Role, Talent } from '../../../helper/type';
import {
  openModal,
  setFlyoutOpen,
  setMenuMode,
} from '../../../store/app.slice';
import SkeletonDirectoryCard from '../../../component/Skeleton/DirectoryCard';
import DirectoryCard from './DirectoryCard';
import { useResetScroll } from '../../../helper/hook';
import { RootState } from '../../../store/store';
import { featureToggle } from '../../../../config/app.config';
import matchRoleForTalent from '../../../helper/job/matchRoleForTalent';

import './Directory.scss';

const DirectoryPage: FunctionComponent = () => {
  useResetScroll();
  const [data, setData] = useState<Project[]>([]);
  const [dataRoles, setDataRoles] = useState<Record<string, Role[]>>({});
  const [key, setKey] = useState<string>('');
  const [observer, setObserver] = useState<IntersectionObserver>();
  const [currentLastItem, setCurretLastItem] = useState<HTMLElement>();
  const [lastFetchedTime, setLastFetchedTime] = useState<Date>();
  const { authUser, talent } = useSelector((state: RootState) => state.user);
  const { loadStack } = useSelector((state: RootState) => state.app);
  const isLoading = loadStack.includes('job/project/list/active');

  const dispatch = useDispatch();
  const jobsService = useJobService();

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);
  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  const fetchDataRoles = async (projects: Project[]) => {
    const mappedRoles: Record<string, Role[]> = {};
    const fetchedRoles = await Promise.allSettled(
      projects.map((proj) => jobsService.getRolesByProject(proj.id))
    );
    fetchedRoles.forEach((roleRes, idx) => {
      if (roleRes.status === 'fulfilled') {
        const roles = roleRes.value;
        mappedRoles[projects[idx].id] = roles;
      }
    });

    return mappedRoles;
  };

  const sortByPublishAndRolePriority = useCallback(
    (a: Project, b: Project) => {
      const publishOrder =
        b.publishedAt?.localeCompare(a.publishedAt ?? '') ?? 0;
      if (publishOrder !== 0) return publishOrder;

      const rolePriorityA = dataRoles[a.id].reduce(
        (totalScore, role) =>
          totalScore + matchRoleForTalent(talent as Talent, role),
        0
      );
      const rolePriorityB = dataRoles[b.id].reduce(
        (totalScore, role) =>
          totalScore + matchRoleForTalent(talent as Talent, role),
        0
      );
      return rolePriorityB - rolePriorityA;
    },
    [dataRoles, talent]
  );

  useEffect(() => {
    (async () => {
      const res = await jobsService.getAllActiveProjects();
      const mappedRoles = await fetchDataRoles(res.data);
      const sortedData = res.data.sort(sortByPublishAndRolePriority);
      setData(sortedData);
      setDataRoles(mappedRoles);
      setKey(res.key);
      setLastFetchedTime(new Date());
    })();
  }, []);

  useEffect(() => {
    if (!featureToggle.invoice) return;

    if (authUser.username && talent.phoneNumber && talent.reviewPaymentMethod) {
      dispatch(
        openModal({
          modal: 'prompt-confirm-duitnow',
          config: {
            context: {
              phoneNumber: talent.phoneNumber,
              phoneNumberPrefix: talent.phoneNumberPrefix,
            },
            modalProps: { onCancel: undefined },
          },
        })
      );
    }
  }, [authUser, talent]);

  const handleLoadMore = useCallback(async () => {
    if (key == '') {
      return;
    }

    const lastFetchedWithin15Mins =
      lastFetchedTime && differenceInMinutes(new Date(), lastFetchedTime) < 15;

    const res = await jobsService.getAllActiveProjects(
      lastFetchedWithin15Mins ? key : undefined
    );
    const mappedRoles = await fetchDataRoles(res.data);
    const sortedData = res.data.sort(sortByPublishAndRolePriority);
    setData((prevData) =>
      lastFetchedWithin15Mins ? prevData.concat(sortedData) : sortedData
    );
    setDataRoles((prev) => ({ ...prev, ...mappedRoles }));
    setKey(res.key);
    setLastFetchedTime(new Date());
  }, [key, lastFetchedTime, sortByPublishAndRolePriority]);

  useEffect(() => {
    const opts = {
      root: null,
      rootMargin: '0px',
      threshold: 0.7,
    };

    const observer = new IntersectionObserver((entry) => {
      const currentLastItem = entry[0];
      if (currentLastItem.isIntersecting) {
        handleLoadMore();
      }
    }, opts);

    setObserver(observer);

    return () => observer.disconnect();
  }, [handleLoadMore]);

  useEffect(() => {
    if (observer && currentLastItem) {
      observer.disconnect();
      observer.observe(currentLastItem);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, currentLastItem]);

  return (
    <Page className='page-job-directory talent-page'>
      {!isLoading && data.length === 0 ? (
        <Div className='zero-state'>
          <Empty description={<>No active projects</>} />
        </Div>
      ) : null}
      {data.map((project, index) => {
        const isLastItem = index === data.length - 1;
        return (
          <div
            ref={(el) => {
              if (isLastItem && el) {
                setCurretLastItem(el);
              }
            }}
            key={project.id}
          >
            <DirectoryCard
              project={project}
              preloadRoles={dataRoles[project.id]}
            />
          </div>
        );
      })}
      {!isLoading && !key && data.length > 0 && (
        <div>
          <div className='end-of-list'>
            <span>That&apos;s all the current active projects!</span>
          </div>
        </div>
      )}
      <SkeletonDirectoryCard />
      {key && !isLoading ? (
        <Button mh='s' block onClick={handleLoadMore}>
          Load More
        </Button>
      ) : null}
      <Div mb='xxl' />
    </Page>
  );
};

export default DirectoryPage;
