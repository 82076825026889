import React, { FC } from 'react';
import { Typography, Empty } from 'antd';
import { Div } from '../../../framework';
import ProjectCard from '../../../component/Card/ProjectCard/ProjectCard';
import SkeletonProjectCard from '../../../component/Skeleton/ProjectCard';
import { useResetScroll } from '../../../helper/hook';
import { Project } from '../../../helper/type';

import './Projects.scss';

const { Link } = Typography;

type Props = {
  projects: Project[];
  type: 'current' | 'past' | 'unpaid';
};

const ProjectsPage: FC<Props> = ({ projects, type }) => {
  useResetScroll();

  return (
    <Div className='tab-hirer-projects'>
      <SkeletonProjectCard>
        {projects.length ? (
          projects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              isUnpaid={type === 'unpaid'}
            />
          ))
        ) : (
          <Div className='zero-state'>
            <Empty
              description={
                <>
                  No {type === 'current' ? 'active' : type} projects
                  <br />
                  <Link href='/hirer/project' target='_blank'>
                    Create a new project
                  </Link>
                </>
              }
            />
          </Div>
        )}
      </SkeletonProjectCard>
    </Div>
  );
};

export default ProjectsPage;
