import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Div } from '../../framework';
import { roundDecimalPlace } from '../../helper/object';
import {
  Project,
  Role,
  SelfBilledInvoice,
  SelfBilledInvoiceStatus,
} from '../../helper/type';
import { useApplications } from '../../hook/useApplications';

type Props = {
  invoice: SelfBilledInvoice;
  project: Project;
  roles: Role[];
};

const TalentInvoiceItems: FC<Props> = ({ invoice, project, roles }) => {
  const history = useHistory();
  const { mappedPastApplications } = useApplications({
    initLoadAll: true,
    type: 'past',
    withdraw: false,
  });

  const roleAllowances = useMemo(() => {
    const allowances: any[] = [];
    const appliedRoles = roles.filter(
      (role) => mappedPastApplications[role.id]
    );
    appliedRoles.forEach((role) => {
      role.allowances?.forEach((allw) => {
        allowances.push({
          ...allw,
          label: `${allw.allowanceType}${
            appliedRoles.length > 1 ? ` (${role.name})` : ''
          }`,
          roleId: role.id,
        });
      });
    });

    return allowances;
  }, [roles, mappedPastApplications]);

  return (
    <Div>
      {invoice.items?.map(
        (item, idx) =>
          item.description.startsWith('Talent Booking:') && (
            <Div
              key={`${idx}-${item.description}`}
              flex
              mb='xl'
              className='invoice-item'
            >
              <Div>
                <Div>{item.description}</Div>
              </Div>
              <span>MYR {roundDecimalPlace(item.amount, 2).toFixed(2)}</span>
            </Div>
          )
      )}
      {((invoice.status === SelfBilledInvoiceStatus.DRAFT &&
        roleAllowances.length > 0) ||
        invoice.items?.some((item) =>
          item.description.startsWith('Allowance:')
        )) && (
        <Div className='small text-muted bold' mb='s'>
          Allowance
        </Div>
      )}
      {invoice.items?.map(
        (item, idx) =>
          !item.description.startsWith('Talent Booking:') && (
            <Div key={idx} flex mb='m' className='invoice-item'>
              <Button
                ghost
                className='btn-allowance-item'
                onClick={() => {
                  const mode =
                    invoice.status === SelfBilledInvoiceStatus.DRAFT
                      ? 'edit'
                      : 'view';
                  history.push(
                    `/talent/invoice/${invoice.id}/allowance/${mode}?projectId=${project.id}&idx=${idx}`,
                    { roleAllowances }
                  );
                }}
              >
                {item.description}
              </Button>
              <span>MYR {roundDecimalPlace(item.amount, 2).toFixed(2)}</span>
            </Div>
          )
      )}

      <Div mt='xxl'>
        {invoice.status === SelfBilledInvoiceStatus.DRAFT &&
        roleAllowances.length > 0 &&
        (invoice.items ?? []).filter((item) =>
          item.description.startsWith('Allowance:')
        ).length < roleAllowances.length ? (
          <Button
            ghost
            className='btn-add-allowance'
            onClick={() =>
              history.push(
                `/talent/invoice/${invoice.id}/allowance/new?projectId=${project.id}`,
                { roleAllowances }
              )
            }
          >
            <PlusCircleOutlined /> Add Allowance
          </Button>
        ) : null}
        <Div mv='xl'>
          <hr />
        </Div>
      </Div>
    </Div>
  );
};

export default TalentInvoiceItems;
