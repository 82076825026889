import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Typography, Breadcrumb, Tag, Tabs, Form, Modal, Button } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import AvatarUpload, {
  AvatarUploadHandle,
} from '../../../component/AvatarUpload/AvatarUpload';
import CompanyInfo, {
  CompanyInfoValues,
  getFormFields as getCompanyFormFields,
  setFormFields as setCompanyFormFields,
} from '../../../component/HirerForm/CompanyInfo';
import ProfileInfo, {
  ProfileInfoValues,
} from '../../../component/HirerForm/ProfileInfo';
import { Div, Page } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { useHirerService } from '../../../service/hirer.service';
import { setFlyoutOpen } from '../../../store/app.slice';
import { setCompanyProfile } from '../../../store/hirer.slice';
import { RootState } from '../../../store/store';
import HirerUsersTab from './HirerUsersTab';

import './Profile.scss';

const { Title, Paragraph } = Typography;

const HirerProfile = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const hirerService = useHirerService();
  const { authHirer } = useSelector((state: RootState) => state.user);
  const { companyProfile } = useSelector((state: RootState) => state.hirer);
  const [companyForm] = Form.useForm();
  const [profileForm] = Form.useForm();
  const avatarUploadRef = useRef<AvatarUploadHandle>(null);
  const [companyAvatar, setCompanyAvatar] = useState<{
    original: string;
    thumbnail: string;
  }>();

  const [currentTab, setCurrentTab] = useState(
    new URLSearchParams(search).get('tab') ?? 'company'
  );
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [saveChangeHandler, setSaveChangeHandler] =
    useState<() => Promise<void>>();
  const [discardChangeHandler, setDiscardChangeHandler] =
    useState<() => void>();
  const [isFormChanged, setIsFormChanged] = useState(false);

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
  }, []);

  useEffect(() => {
    if (!companyProfile && authHirer.hirerId) {
      (async () => {
        const hirer = await hirerService.getHirer(authHirer.hirerId);
        dispatch(setCompanyProfile(hirer));
      })();
    }
  }, []);

  useEffect(() => {
    if (companyProfile.profilePic && companyProfile.profilePicThumbnail) {
      setCompanyAvatar({
        original: companyProfile.profilePic,
        thumbnail: companyProfile.profilePicThumbnail,
      });
    }
  }, [companyProfile.profilePic, companyProfile.profilePicThumbnail]);

  const getFormChanges = (newValues: Record<string, unknown>) => {
    const oldValues: Record<string, unknown> = {};
    Object.keys(newValues).forEach((key) => {
      newValues[key] = newValues[key] ?? '';
      oldValues[key] = (companyProfile as Record<string, unknown>)[key] ?? '';
    });

    const changedFields = Object.keys(newValues).filter(
      (key) => newValues[key] !== oldValues[key]
    );

    return { changedFields, oldValues };
  };

  const promptSaveModal = ({
    saveHandler,
    discardHandler,
  }: {
    saveHandler: () => Promise<void>;
    discardHandler: () => void;
  }) => {
    setSaveChangeHandler(() => saveHandler);
    setDiscardChangeHandler(() => discardHandler);
    setOpenSaveModal(true);
  };

  const handleChangeTab = (tabKey: string) => {
    if (currentTab === 'company') {
      const newValues = getCompanyFormFields(companyForm);
      const { changedFields, oldValues } = getFormChanges(newValues);

      if (changedFields.length > 0 || isFormChanged) {
        promptSaveModal({
          saveHandler: async () => {
            await handleUpdateCompanyInfo(companyForm.getFieldsValue());
            setCurrentTab(tabKey);
          },
          discardHandler: () => {
            setCompanyFormFields(companyForm, oldValues);
            setCurrentTab(tabKey);
          },
        });

        return;
      }
    } else if (currentTab === 'profile') {
      const newValues = profileForm.getFieldsValue();
      const { changedFields, oldValues } = getFormChanges(newValues);

      const isAvatarChanged = avatarUploadRef.current?.hasChanged();
      if (changedFields.length > 0 || isFormChanged || isAvatarChanged) {
        promptSaveModal({
          saveHandler: async () => {
            await handleUpdateCompanyProfile(newValues);
            setCurrentTab(tabKey);
          },
          discardHandler: () => {
            profileForm.setFieldsValue(oldValues);
            avatarUploadRef.current?.reset();
            setCurrentTab(tabKey);
          },
        });

        return;
      }
    }

    setCurrentTab(tabKey);
  };

  const handleUpdateCompanyInfo = async (values: CompanyInfoValues) => {
    await companyForm.validateFields();

    const { companyContactNumber, companySsmRegNumber, ...companyInfo } =
      values;
    const company = await hirerService.updateHirer(authHirer.hirerId, {
      ...companyInfo,
      companyContactNumber: companyContactNumber.phoneNumber,
      companyContactNumberPrefix: companyContactNumber.phoneNumberPrefix,
      companySsmRegNumber: companySsmRegNumber || null,
    });
    dispatch(setCompanyProfile(company));
    setIsFormChanged(false);
  };

  const handleUpdateCompanyProfile = async (values: ProfileInfoValues) => {
    await profileForm.validateFields();
    const [profilePhoto] = (await avatarUploadRef.current?.update()) ?? [];

    const submitValue: Record<string, unknown> = {
      ...values,
      profilePic: profilePhoto?.original,
      profilePicThumbnail: profilePhoto?.thumbnail,
    };
    Object.keys(submitValue).forEach((key) => {
      const val = submitValue[key];
      submitValue[key] = val || val === 0 ? val : null;
    });

    if (submitValue.describeAs !== 'Others')
      submitValue.describeAsOthers = null;

    const company = await hirerService.updateHirer(
      authHirer.hirerId,
      submitValue
    );
    dispatch(setCompanyProfile(company));
    setIsFormChanged(false);
  };

  return (
    <Page className='page-hirer-profile'>
      <Helmet>
        <title>My Company Account - HeyCast.Me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item>My Company Account</Breadcrumb.Item>
      </Breadcrumb>

      <Div pt='m' pb='xxl'>
        <Title level={2}>{companyProfile.companyName}</Title>
        <Tag
          color={
            !companyProfile.approvalStatus
              ? '#444444'
              : companyProfile.approvalStatus === 'approved'
              ? '#16B55E'
              : '#F03847'
          }
          style={{ textTransform: 'capitalize', borderRadius: '4px' }}
        >
          {companyProfile.approvalStatus ?? 'pending approval'}
        </Tag>
      </Div>

      <Tabs
        activeKey={currentTab}
        onChange={handleChangeTab}
        items={[
          {
            label: 'Company Info',
            key: 'company',
            children: (
              <CompanyInfo
                form={companyForm}
                values={companyProfile}
                onValuesChange={() => setIsFormChanged(true)}
                onFinish={handleUpdateCompanyInfo}
              />
            ),
          },
          {
            label: 'Profile',
            key: 'profile',
            children: (
              <>
                <Div pt='m'>
                  <Div pb='m'>
                    <span className='bold'>Profile photo</span>
                  </Div>
                  <AvatarUpload
                    ref={avatarUploadRef}
                    scope='hirer'
                    directory={authHirer.hirerId}
                    value={companyAvatar}
                    removable
                    maxFileSizeInKb={1 * 1024}
                  />
                </Div>

                <Div pv='m'>
                  <hr style={{ border: 0, borderTop: '1px solid #A4A4A4' }} />
                </Div>

                <ProfileInfo
                  form={profileForm}
                  values={companyProfile}
                  onValuesChange={() => setIsFormChanged(true)}
                  onFinish={handleUpdateCompanyProfile}
                />
              </>
            ),
          },
          {
            label: 'Users',
            key: 'users',
            children: <HirerUsersTab />,
          },
        ]}
      />

      <Prompt
        when={isFormChanged}
        message='You have unsaved changes. Are you sure you want to leave?'
      />
      <Modal
        open={openSaveModal}
        footer={false}
        onCancel={() => setOpenSaveModal(false)}
      >
        <Div style={{ textAlign: 'center' }} pt='m'>
          <Title level={3}>
            <Div>
              <WarningTwoTone twoToneColor='red' />
            </Div>
            Unsaved changes
          </Title>
        </Div>

        <Paragraph style={{ textAlign: 'center' }}>
          You have made changes that have not been saved. If you leave this page
          without saving, your changes will be lost.
        </Paragraph>

        <Div ph='xl' mt='xxl'>
          <Button
            type='primary'
            size='large'
            block
            onClick={() => {
              saveChangeHandler?.call(null);
              setSaveChangeHandler(undefined);
              setIsFormChanged(false);
              setOpenSaveModal(false);
            }}
          >
            Save Changes
          </Button>
          <Button
            type='link'
            size='large'
            block
            style={{ marginTop: '1rem' }}
            onClick={() => {
              discardChangeHandler?.call(null);
              setDiscardChangeHandler(undefined);
              setIsFormChanged(false);
              setOpenSaveModal(false);
            }}
          >
            Discard Changes
          </Button>
        </Div>
      </Modal>
    </Page>
  );
};

export default HirerProfile;
