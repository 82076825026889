import React, { ComponentProps, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { BottomNavigation, Div } from '../../framework';
import { useJobService } from '../../service/job.service';
import { useResetScroll } from '../../helper/hook';
import { useAsyncData } from '../../hook/useAsyncData';
import Menu from '../../component/Menu/Menu';
import Footer from '../../component/Footer/Footer';
import OnboardingBanner from '../../component/OnboardingBanner/OnboardingBanner';
import { setMenuMode } from '../../store/app.slice';
import { serviceDomain } from '../../../config/app.config';
import { RootState } from '../../store/store';
import DirectoryCard from '../Job/Directory/DirectoryCard';
import { setApplications } from '../../store/talent.slice';
import { setImpersonator } from '../../store/user.slice';

import './Project.scss';

const ProjectPage = (props: ComponentProps<any>) => {
  useResetScroll();
  const jobService = useJobService();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const { id } = useParams<{ id: string }>();
  const { data = [], error } = useAsyncData(ProjectPage, props, {
    id,
  });
  const { authUser, isTalentOnboarded, impersonator } = useSelector(
    (state: RootState) => state.user
  );

  const [project = {}, roles = []] = data;
  const isImpersonator =
    impersonator.impersonateId && impersonator.impersonateKey;

  useEffect(() => {
    const impersonateId = new URLSearchParams(search).get('imid') as string;
    const impersonateKey = new URLSearchParams(search).get('imkey') as string;

    if (impersonateId && impersonateKey) {
      dispatch(setImpersonator({ impersonateId, impersonateKey }));
    }
  }, [search]);

  useEffect(() => {
    (async () => {
      // only do this api call if logged in to prevent redirect
      if (authUser.username || isImpersonator) {
        let nextKey: any = undefined;
        let applications: any[] = [];

        while (nextKey !== '') {
          const { data, key } = await jobService.getApplicationsByTalent(
            { type: 'current' },
            nextKey
          );
          applications = applications.concat(data);
          nextKey = key;
        }

        dispatch(setApplications({ applications, type: 'current' }));
      }
    })();
  }, [authUser, isImpersonator]);

  useEffect(() => {
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  if (error) {
    console.log(error);
    // return <Redirect to='/' />;
  }

  const maxMetaDescriptionLength = 80;

  const metaDescription = project?.description
    ? `${project?.description.slice(0, maxMetaDescriptionLength)}${
        project?.description.length > maxMetaDescriptionLength ? '...' : ''
      }`
    : 'The right role. The right talent. The right platform.';

  return (
    <Div className='page-project'>
      <Div className='mobile-view'>
        <Menu />
        <Helmet>
          <title>{`${project?.title || 'Project'}`} - HeyCast.Me</title>
          <meta name='description' content={metaDescription} />
          <meta
            property='og:title'
            content={`${project?.title} | HeyCast.Me`}
          />
          <meta property='og:url' content={`${serviceDomain.ui}/p/${id}`} />
          <meta property='og:description' content={metaDescription} />
          <meta
            property='og:image'
            content='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/confetti-thumbnail.jpg'
          />
        </Helmet>
        {authUser.username ? <OnboardingBanner /> : null}
        <Div
          className='project'
          style={{ marginBottom: authUser.name ? '100px' : '10px' }}
        >
          {project && project.id && (
            <DirectoryCard
              project={project}
              preloadRoles={roles}
            ></DirectoryCard>
          )}
        </Div>
        {authUser.name && isTalentOnboarded ? (
          <BottomNavigation type='talent' />
        ) : (
          <Footer />
        )}
      </Div>
    </Div>
  );
};

ProjectPage.asyncData = ({ id }: { [key: string]: string }) => {
  const jobService = useJobService();
  return Promise.all([
    jobService.getProject(id),
    jobService.getRolesByProject(id),
  ]);
};

export default ProjectPage;
