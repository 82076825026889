import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, Button, Form } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import AvatarUpload, {
  AvatarUploadHandle,
} from '../../../component/AvatarUpload/AvatarUpload';
import { Div, Page } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { Project, Role } from '../../../helper/type';
import { HIRER_ROUTES } from '../../../route/constant';
import { useHirerService } from '../../../service/hirer.service';
import { useJobService } from '../../../service/job.service';
import { setCompanyProfile, setProject } from '../../../store/hirer.slice';
import { RootState } from '../../../store/store';
import CompanyInfo, {
  CompanyInfoValues,
} from '../../../component/HirerForm/CompanyInfo';

import './RegisterCompany.scss';

const { Title, Paragraph } = Typography;

const RegisterCompany = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const history = useHistory();
  const { state: locationState = {} } = useLocation<{
    savedProject?: Project;
    savedRoles?: Role[];
  }>();
  const { authHirer } = useSelector((state: RootState) => state.user);
  const hirerService = useHirerService();
  const jobService = useJobService();
  const [form] = Form.useForm();
  const avatarUploadRef = useRef<AvatarUploadHandle>(null);

  const [isInfoSubmitted, setIsInfoSubmitted] = useState(false);
  const [formError, setFormError] = useState<string>();

  const handleSubmitCompanyForm = async (values: CompanyInfoValues) => {
    try {
      const [profilePhoto] = (await avatarUploadRef.current?.update()) ?? [];

      const hirerCompany = await hirerService.createCompany({
        ...values,
        companyContactNumber: values.companyContactNumber.phoneNumber,
        companyContactNumberPrefix:
          values.companyContactNumber.phoneNumberPrefix,
        profilePic: profilePhoto?.original as string,
        profilePicThumbnail: profilePhoto?.thumbnail as string,
        hirerId: authHirer.hirerId,
      });
      dispatch(setCompanyProfile(hirerCompany));

      await createSavedJob(hirerCompany.hirerId);

      setIsInfoSubmitted(true);
    } catch (error) {
      const e = error as AxiosError;
      setFormError(e.response?.data?.message);
    }
  };

  const createSavedJob = async (hirerId: string) => {
    const { savedProject, savedRoles } = locationState;
    let project: Project | undefined;
    if (savedProject) {
      project = await jobService.createProject({
        ...savedProject,
        hirerId,
      });

      project && dispatch(setProject(project));
    }

    if (project && savedRoles) {
      const roles = savedRoles.map((role) =>
        jobService.createRole({
          ...role,
          projectId: project?.id,
        })
      );

      await Promise.all(roles);
    }
  };

  const renderCompanyRegisterForm = () => (
    <>
      <Div pt='xxl'>
        <Title level={2} className='text-center'>
          Tell us about your company
        </Title>
      </Div>
      <Paragraph className='text-center'>
        We maintain our quality by ensuring all companies are verified.
      </Paragraph>

      <hr />

      <Div pb='xl' pt='m'>
        <Div>
          <span className='bold'>Profile photo</span>
        </Div>
        <Paragraph>
          Upload your company logo so that talents can recognise you!
        </Paragraph>
        <AvatarUpload
          ref={avatarUploadRef}
          scope='hirer'
          directory={authHirer.hirerId}
          removable
          maxFileSizeInKb={1 * 1024}
        />
      </Div>

      <CompanyInfo
        form={form}
        onFinish={handleSubmitCompanyForm}
        formError={formError}
      />
    </>
  );

  const renderSubmitFeedback = () => (
    <>
      <Div pt='xxl'>
        <Title level={2} className='text-center'>
          <Div>
            <CheckCircleOutlined />
          </Div>
          Your account has been sent for approval
        </Title>
      </Div>
      <Div pb='xl'>
        <Paragraph className='text-center'>
          An update will be sent to {authHirer.email} within 24 hours.
        </Paragraph>
      </Div>

      <Button
        type='primary'
        size='large'
        block
        style={{ marginBottom: '1rem' }}
        onClick={() => history.push(HIRER_ROUTES.DASHBOARD)}
      >
        View My Projects
      </Button>
      <Button
        type='default'
        size='large'
        block
        onClick={() => history.push(HIRER_ROUTES.PROFILE)}
      >
        View Company Account
      </Button>
    </>
  );

  return (
    <Page className='page-hirer-register-company'>
      {isInfoSubmitted ? renderSubmitFeedback() : renderCompanyRegisterForm()}
    </Page>
  );
};

export default RegisterCompany;
