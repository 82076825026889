import React, { FC, useState } from 'react';
import { Div } from '../../../../framework';
import { Button, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

import './WrittenReview.scss';

const { Paragraph } = Typography;

type Props = {
  value?: string;
  onValue: (writtenReview: string) => void;
  onGoBack: () => void;
};

const WrittenReview: FC<Props> = ({ value, onValue, onGoBack }) => {
  const [writtenReview, setWrittenReview] = useState(value);
  const [error, setError] = useState(false);

  const submitValue = () => {
    if (!writtenReview) {
      setError(true);
      return;
    }

    onValue(writtenReview);
  };

  return (
    <Div flex className='rate-written-review'>
      <Div className='title'>Share your thoughts</Div>
      <Paragraph className='subtitle'>
        Your input is valuable in shaping the future of our talents. Create
        meaningful feedback with these tips:
      </Paragraph>

      <Div className='check-list'>
        <Paragraph>
          <CheckCircleFilled className='check-icon' /> Keep your tone positive
          and supportive
        </Paragraph>
        <Paragraph>
          <CheckCircleFilled className='check-icon' /> Highlight their strengths
        </Paragraph>
        <Paragraph>
          <CheckCircleFilled className='check-icon' /> Provide suggestions for
          how to improve
        </Paragraph>
      </Div>

      <Div className='txt-written-review'>
        <TextArea
          rows={5}
          status={error ? 'error' : ''}
          placeholder='Write your thoughts here'
          defaultValue={writtenReview}
          onChange={(e) => setWrittenReview(e.target.value)}
        />
        {error && (
          <Div className='error-message'>
            Please write a review before submitting
          </Div>
        )}
      </Div>

      <Div mb='m' className='note'>
        Your review will be visible to the talent and other hirers
      </Div>

      <Button
        type='primary'
        size='large'
        block
        style={{ marginBottom: '1rem' }}
        onClick={submitValue}
      >
        Submit
      </Button>

      <Button size='large' block onClick={onGoBack}>
        Back
      </Button>
    </Div>
  );
};

export default WrittenReview;
