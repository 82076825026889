import React, { FC, useState } from 'react';
import {
  Button,
  Form,
  FormInstance,
  FormListFieldData,
  InputNumber,
  Select,
} from 'antd';
import { Div } from '../../../framework';
import { projectAllowance } from '../../../helper/constants';
import { CloseOutlined } from '@ant-design/icons';

import './AllowanceFormItem.scss';

const { Option } = Select;

type Props = {
  form: FormInstance;
  item: FormListFieldData;
  remove: () => void;
};

const AllowanceFormItem: FC<Props> = ({ form, item, remove }) => {
  const [type, setType] = useState<string>();

  return (
    <Form.Item className='allowance-form-item' key={item.key} name={item.name}>
      <Div flex mb='l'>
        <Form.Item
          label='Type of Allowance'
          name={[item.name, 'allowanceType']}
          rules={[
            { required: true, message: 'Please specify a type of allowance.' },
            {
              validator: async (_, val) => {
                const allowances = form
                  .getFieldValue(['allowances'])
                  ?.filter(
                    (allw: unknown, idx: number) =>
                      Boolean(allw) && idx !== item.name
                  )
                  .map((allw: any) => allw.allowanceType);

                if (allowances.includes(val))
                  throw new Error(`Duplicate Type of Allowance: ${val}`);
              },
            },
          ]}
          className='allowance-type'
        >
          <Select placeholder='Choose allowance' onChange={setType}>
            {projectAllowance.map((allw) => (
              <Option key={allw} value={allw}>
                {allw}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Button type='text' className='btn-remove-allowance' onClick={remove}>
          <CloseOutlined />
        </Button>
      </Div>
      {type === 'Meal' && (
        <Form.Item
          label='Max Amount'
          name={[item.name, 'maxAmount']}
          rules={[{ required: true, message: 'Please specify max amount.' }]}
        >
          <InputNumber min={0} addonBefore='MYR' />
        </Form.Item>
      )}
      {type === 'OT' && (
        <Form.Item
          label='Per hour rate'
          name={[item.name, 'hourRate']}
          rules={[{ required: true, message: 'Please specify hour rate.' }]}
        >
          <InputNumber min={0} addonBefore='MYR' />
        </Form.Item>
      )}
      {type === 'Travel/Transportation' && (
        <Form.Item
          label='Amount'
          name={[item.name, 'amount']}
          rules={[{ required: true, message: 'Please specify amount.' }]}
        >
          <InputNumber min={0} addonBefore='MYR' />
        </Form.Item>
      )}
    </Form.Item>
  );
};

export default AllowanceFormItem;
