import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Typography, Breadcrumb, Steps, message } from 'antd';
import HirerToRateCard from '../../../component/Card/HirerToRateCard/HirerToRateCard';
import { Div } from '../../../framework';
import { RootState } from '../../../store/store';
import { useResetScroll } from '../../../helper/hook';
import { PendingRating, Rating } from '../../../helper/type';
import { HIRER_ROUTES } from '../../../route/constant';
import { useRatingService } from '../../../service/rating.service';
import HireAgain from './Form/HireAgain';
import PerformanceMetrics from './Form/PerformanceMetrics';
import WrittenReview from './Form/WrittenReview';
import { setPendingRatings } from '../../../store/hirer.slice';

import './RateTalent.scss';

const { Title } = Typography;

const RateTalentPage = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const ratingService = useRatingService();
  const { projectId, roleId } = useParams<Record<string, string>>();
  const { authHirer } = useSelector((state: RootState) => state.user);
  const { companyProfile, mappedProjects, pendingRatings } = useSelector(
    (state: RootState) => state.hirer
  );

  const [currentStep, setCurrentStep] = useState(0);
  const [currPendingRating, setCurrPendingRating] = useState<PendingRating>();
  const [currEditRating, setCurrEditRating] = useState<Rating>();

  const queryParams = new URLSearchParams(search);
  const talentId = queryParams.get('talentId') as string;
  const mode = queryParams.get('mode') as string;

  const isEditMode = mode === 'edit';

  const getPendingRating = async () => {
    let pendingToRate = pendingRatings.find(
      (pr) => pr.roleId === roleId && pr.talentId === talentId
    );
    if (!pendingToRate) {
      pendingToRate = await ratingService.getPendingToRate(
        authHirer.hirerId,
        talentId,
        roleId
      );
      dispatch(setPendingRatings([...pendingRatings, pendingToRate]));
    }

    return pendingToRate;
  };
  useEffect(() => {
    if (!authHirer.hirerId) return;

    (async () => {
      if (!isEditMode) {
        const pendingToRate = await getPendingRating();
        setCurrPendingRating(pendingToRate);
        if (!pendingToRate) {
          message.error('Unable to rate talent for this role.');
          history.push(`${HIRER_ROUTES.DASHBOARD}?tab=rate`);
        }
      } else {
        const ratingToEdit = await ratingService.getRating(talentId, roleId);
        setCurrEditRating(ratingToEdit);
        if (!ratingToEdit) {
          message.error('Rating not found.');
          history.push(`${HIRER_ROUTES.DASHBOARD}?tab=rate`);
        } else if (ratingToEdit.status === 'PUBLISHED') {
          message.error(
            'Rating has been published, you are not allow to make further edits.'
          );
          history.push(`/hirer/project/${projectId}/ratings`);
        }
      }
    })();
  }, [authHirer.hirerId]);

  const createRating = async (hireAgain: Rating['hireAgain']) => {
    if (!currPendingRating) {
      message.error(
        'Oops, somethings is not loaded properly, please refresh the page and try again.'
      );
      return;
    }

    const newRating = await ratingService.createRating({
      talentId,
      roleId,
      hirerId: authHirer.hirerId,
      userId: authHirer.userId,
      projectId,
      shootingDate: currPendingRating.shootingDate,
      authorCompanyName: companyProfile.companyName,
      authorUserName: authHirer.name,
      authorProfilePicThumb: companyProfile.profilePicThumbnail,
      hireAgain,
    });

    setCurrEditRating(newRating);
  };

  const updateRating = async (values: Partial<Rating>) => {
    const updatedRating = await ratingService.updateRating(
      talentId,
      roleId,
      values
    );
    setCurrEditRating(updatedRating);
  };

  return (
    <Div className='page-rate-talent'>
      <Helmet>
        <title>Rate - HeyCast.Me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='/hirer/home'>All Projects</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{mappedProjects[projectId]?.title}</Breadcrumb.Item>
        <Breadcrumb.Item>Rate Talent</Breadcrumb.Item>
      </Breadcrumb>

      <Div className='title-container'>
        <Title level={4}>Rate Talent</Title>
      </Div>

      <Div mb='xl'>
        <HirerToRateCard
          noStyle
          projectId={projectId}
          roleId={roleId}
          talentId={talentId}
        />
      </Div>

      <Div mb='xl'>
        <Steps
          size='small'
          direction='horizontal'
          responsive={false}
          current={currentStep}
          items={[
            {
              title: (
                <span
                  className='cursor-pointer'
                  onClick={() => isEditMode && setCurrentStep(0)}
                >
                  Part 1
                </span>
              ),
            },
            {
              title: (
                <span
                  className='cursor-pointer'
                  onClick={() =>
                    isEditMode &&
                    currEditRating?.hireAgain !== undefined &&
                    setCurrentStep(1)
                  }
                >
                  Part 2
                </span>
              ),
            },
            {
              title: (
                <span
                  className='cursor-pointer'
                  onClick={() =>
                    isEditMode &&
                    currEditRating?.overallPerformance !== undefined &&
                    setCurrentStep(2)
                  }
                >
                  Part 3
                </span>
              ),
            },
          ]}
        />
      </Div>

      <Div pt='xl'>
        {currentStep === 0 && (
          <HireAgain
            onValue={async (hireAgain) => {
              if (!isEditMode && !currEditRating) {
                await createRating(hireAgain);
              } else if (hireAgain !== currEditRating?.hireAgain) {
                await updateRating({ hireAgain });
              }
              setCurrentStep(1);
            }}
          />
        )}
        {currentStep === 1 && (
          <PerformanceMetrics
            values={{
              punctuality: currEditRating?.punctuality ?? 0,
              professionalism: currEditRating?.professionalism ?? 0,
              responsiveness: currEditRating?.responsiveness ?? 0,
              dialogueMastery: currEditRating?.dialogueMastery ?? 0,
              profileRepresentation: currEditRating?.profileRepresentation ?? 0,
              overallPerformance: currEditRating?.overallPerformance ?? 0,
            }}
            onValue={async (value) => {
              if (
                currEditRating &&
                Object.keys(value).some((k) => {
                  const key = k as keyof typeof value;
                  return value[key] !== currEditRating[key];
                })
              ) {
                await updateRating(value);
              }
              setCurrentStep(2);
            }}
            onGoBack={() => setCurrentStep(0)}
          />
        )}
        {currentStep === 2 && (
          <WrittenReview
            value={currEditRating?.writtenReview}
            onValue={async (writtenReview) => {
              if (writtenReview !== currEditRating?.writtenReview) {
                await updateRating({ writtenReview });
              }
              history.push(
                isEditMode
                  ? `/hirer/project/${projectId}/ratings`
                  : HIRER_ROUTES.RATE_TALENT_COMPLETE
              );
            }}
            onGoBack={() => setCurrentStep(1)}
          />
        )}
      </Div>
    </Div>
  );
};

export default RateTalentPage;
