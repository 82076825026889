import React from 'react';
import { Typography } from 'antd';
import { StarFilled } from '@ant-design/icons';
import { Div } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import ToRateTabContent from '../Project/ToRateTabContent';

import './RateTalentComplete.scss';

const { Title, Paragraph } = Typography;

const RateTalentCompletePage = () => {
  useResetScroll();

  return (
    <Div className='page-rate-talent-complete'>
      <Div className='top-content'>
        <Div flex className='stars-wrapper'>
          <StarFilled />
          <StarFilled />
          <StarFilled />
        </Div>

        <Div className='title'>
          <Title level={2}>Thanks for the valuable feedback!</Title>
        </Div>

        <Paragraph className='text-center'>
          Your input benefits talents, future hirers, and the community with
          valuable insights and guidance.
          <br />
          <br />
          Ratings can be modified within 24 hours of submission; any adjustments
          after this will not be possible.
        </Paragraph>
      </Div>

      <hr className='separate-line' />

      <Div className='text-center continue-text'>
        Keep the momentum going, continue rating
      </Div>

      <ToRateTabContent />
    </Div>
  );
};

export default RateTalentCompletePage;
