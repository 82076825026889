import React, { FC } from 'react';
import { Collapse } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Div, Button } from '../../framework';
import { RootState } from '../../store/store';
import { openModal } from '../../store/app.slice';
import { SelfBilledInvoice, SelfBilledInvoiceStatus } from '../../helper/type';
import { featureToggle } from '../../../config/app.config';

const { Panel } = Collapse;

type Props = { invoice: SelfBilledInvoice };

const TalentPaymentDetails: FC<Props> = ({ invoice }) => {
  const dispatch = useDispatch();
  const { talent } = useSelector((state: RootState) => state.user);

  const changePaymentMethod = () => {
    if (!featureToggle.invoice) return;

    dispatch(
      openModal({
        modal: 'change-payment-method',
      })
    );
  };

  return (
    <Collapse defaultActiveKey={['1']} ghost expandIconPosition='end'>
      <Panel
        header={<Div className='bold'>Talent Details</Div>}
        key='1'
        className='collapse-talent-details'
      >
        <Div mb='l'>
          <Div className='bold'>Name</Div>
          <Div>{talent.paymentMethod?.beneficieryName}</Div>
        </Div>
        {/* <Div mb='l'>
          <Div className='bold'>NRIC</Div>
          <Div>{nric}</Div>
        </Div> */}
        <Div>
          <Div className='bold'>Payment Method</Div>
          <Div>
            DuitNow - {talent.phoneNumberPrefix}
            {talent.phoneNumber}
          </Div>
          {invoice.status === SelfBilledInvoiceStatus.DRAFT && (
            <Button
              ghost
              className='btn-change-payment-method'
              onClick={changePaymentMethod}
            >
              Change Method
            </Button>
          )}
        </Div>
      </Panel>
    </Collapse>
  );
};

export default TalentPaymentDetails;
