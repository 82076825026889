import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearImpersonator, clearAuthHirer } from '../../../store/user.slice';
import { Page } from '../../../framework';
import { setActivityFlyoutOpen } from '../../../store/app.slice';
import { HIRER_ROUTES } from '../../../route/constant';
import { clearCompanyProfile } from '../../../store/hirer.slice';

const LogoutPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      dispatch(clearAuthHirer());
      dispatch(clearCompanyProfile());
      dispatch(clearImpersonator());

      dispatch(setActivityFlyoutOpen({ isOpen: false }));

      history.replace(HIRER_ROUTES.LOGIN);
    })();
  }, []);

  return <Page className='page-login' />;
};

export default LogoutPage;
