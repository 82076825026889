import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Breadcrumb } from 'antd';
import { Div } from '../../../framework';
import { useResetScroll } from '../../../helper/hook';
import { RootState } from '../../../store/store';
import InvoiceDetails from '../../Invoice/InvoiceDetails';
import { useAccountingService } from '../../../service/accounting.service';
import {
  Project,
  RequestPaymentInvoice,
  RequestPaymentInvoiceStatus,
} from '../../../helper/type';
import { useJobService } from '../../../service/job.service';
import { setRequestPaymentInvoices } from '../../../store/hirer.slice';
import { serviceDomain } from '../../../../config/app.config';

import './InvoiceDetails.scss';

const InvoiceDetailsPage = () => {
  useResetScroll();
  const dispatch = useDispatch();
  const { projectId, invoiceId } = useParams<{
    projectId: string;
    invoiceId: string;
  }>();
  const { mappedProjects, mappedRequestPaymentInvoice } = useSelector(
    (state: RootState) => state.hirer
  );
  const { authHirer } = useSelector((state: RootState) => state.user);
  const accountingService = useAccountingService();
  const jobsService = useJobService();

  const [project, setProject] = useState<Project>();

  const currentInvoice = mappedRequestPaymentInvoice[projectId];

  useEffect(() => {
    (async () => {
      if (!mappedProjects[projectId]) {
        const res = await jobsService.getProject(projectId, 'false');
        setProject(res);
      } else {
        setProject(mappedProjects[projectId]);
      }
    })();
  }, []);

  useEffect(() => {
    if (!mappedRequestPaymentInvoice[projectId]) {
      fetchHirerInvoices();
    }
  }, []);

  const fetchHirerInvoices = async () => {
    if (!authHirer.hirerId) return;

    const { data } =
      await accountingService.listRequestPaymentInvoicesByPayerId(
        authHirer.hirerId
      );
    dispatch(setRequestPaymentInvoices(data));
  };

  const handleSubmitInvoice = async () => {
    if (!currentInvoice.id) return;

    let updatedInvoice: RequestPaymentInvoice = currentInvoice;
    if (
      updatedInvoice.id &&
      updatedInvoice.status === RequestPaymentInvoiceStatus.PENDING_PAYER_REVIEW
    ) {
      updatedInvoice = await accountingService.approveRequestPaymentInvoice(
        updatedInvoice.id
      );
      dispatch(setRequestPaymentInvoices([updatedInvoice]));
    }

    if (
      updatedInvoice.id &&
      updatedInvoice.status ===
        RequestPaymentInvoiceStatus.PENDING_PAYER_MAKE_PAYMENT
    ) {
      updatedInvoice = await accountingService.proceedPayment(
        updatedInvoice.id,
        {
          success_redirect_url: `${serviceDomain.ui}/hirer/payment/successful`,
          failure_redirect_url: `${serviceDomain.ui}/hirer/payment/unsuccessful?projectId=${projectId}&invoiceId=${invoiceId}`,
        }
      );
      dispatch(setRequestPaymentInvoices([updatedInvoice]));
    }

    if (updatedInvoice.paymentGatewayInvoiceUrl)
      window.location.href = updatedInvoice.paymentGatewayInvoiceUrl;
  };

  return (
    <Div className='page-hirer-invoice-details'>
      <Helmet>
        <title>Invoice - HeyCast.Me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='/hirer/home?tab=unpaid'>All Projects</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{mappedProjects[projectId]?.title}</Breadcrumb.Item>
        <Breadcrumb.Item>Invoice</Breadcrumb.Item>
        <Breadcrumb.Item>Details</Breadcrumb.Item>
      </Breadcrumb>

      {currentInvoice && project && (
        <InvoiceDetails
          type='hirer'
          invoice={currentInvoice}
          project={project}
          onSubmitInvoice={handleSubmitInvoice}
        />
      )}
    </Div>
  );
};

export default InvoiceDetailsPage;
