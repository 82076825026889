import React, { FC, useState } from 'react';
import { Button, Rate, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Div } from '../../../../framework';
import { Rating } from '../../../../helper/type';

import './PerformanceMetrics.scss';

type MetricsValue = Pick<
  Rating,
  | 'punctuality'
  | 'professionalism'
  | 'responsiveness'
  | 'dialogueMastery'
  | 'profileRepresentation'
  | 'overallPerformance'
>;

type Props = {
  values?: MetricsValue;
  onValue: (metrics: MetricsValue) => void;
  onGoBack: () => void;
};

const PerformanceMetrics: FC<Props> = ({ values, onValue, onGoBack }) => {
  const [value, setValue] = useState<MetricsValue>({
    punctuality: 0,
    professionalism: 0,
    responsiveness: 0,
    dialogueMastery: 0,
    profileRepresentation: 0,
    overallPerformance: 0,
    ...values,
  });
  const [showError, setShowError] = useState(false);

  const submitValue = () => {
    if (Object.values(value).some((val) => val === 0)) {
      setShowError(true);
      return;
    }

    onValue(value);
  };

  return (
    <Div className='rate-performance-metrics'>
      <Div className='title'>Rate this talent&apos;s performance</Div>

      <MetricsItem
        title='Punctuality'
        tooltip="Assess the talent's punctuality in terms of arriving on set or delivering work on time."
        error={showError && value.punctuality === 0}
        value={value.punctuality ?? 0}
        onChange={(v) => setValue({ ...value, punctuality: v })}
      />
      <hr className='separate-line' />

      <MetricsItem
        title='Professionalism'
        tooltip="Access the talent's behavior and attitude and preparation for the role."
        error={showError && value.professionalism === 0}
        value={value.professionalism ?? 0}
        onChange={(v) => setValue({ ...value, professionalism: v })}
      />
      <hr className='separate-line' />

      <MetricsItem
        title='Responsiveness'
        tooltip='How promptly did the talent respond to messages or request? Within 12 hours consider responsive.'
        error={showError && value.responsiveness === 0}
        value={value.responsiveness ?? 0}
        onChange={(v) => setValue({ ...value, responsiveness: v })}
      />
      <hr className='separate-line' />

      <MetricsItem
        title='Dialogue Mastery'
        tooltip='How well the talent delivers the dialogue.'
        error={showError && value.dialogueMastery === 0}
        value={value.dialogueMastery ?? 0}
        onChange={(v) => setValue({ ...value, dialogueMastery: v })}
      />
      <hr className='separate-line' />

      <MetricsItem
        title='Profile Representation'
        tooltip='To evaluate whether a person in pictures and videos are the same in reality'
        error={showError && value.profileRepresentation === 0}
        value={value.profileRepresentation ?? 0}
        onChange={(v) => setValue({ ...value, profileRepresentation: v })}
      />
      <hr className='separate-line' />

      <MetricsItem
        title='Overall Performance'
        tooltip="Evaluate the talent's overall performance in the project."
        error={showError && value.overallPerformance === 0}
        value={value.overallPerformance ?? 0}
        onChange={(v) => setValue({ ...value, overallPerformance: v })}
      />

      <Div mb='xxl' />

      {showError && (
        <Div className='error-message' mb='m'>
          Uh oh! It seems you missed a rating. Scroll up to check.
        </Div>
      )}

      <Div>
        <Button
          type='primary'
          size='large'
          block
          style={{ marginBottom: '1rem' }}
          onClick={submitValue}
        >
          Continue
        </Button>
        <Button type='default' size='large' block onClick={onGoBack}>
          Back
        </Button>
      </Div>
    </Div>
  );
};

type MetricsItemProps = {
  title: string;
  tooltip: string;
  error: boolean;
  value: number;
  onChange: (value: number) => void;
};
const MetricsItem: FC<MetricsItemProps> = ({
  title,
  tooltip,
  error,
  value,
  onChange,
}) => {
  return (
    <Div>
      <Div flex className='metrics-title'>
        {title}{' '}
        <Tooltip title={tooltip}>
          <InfoCircleOutlined className='title-icon' />
        </Tooltip>
      </Div>
      {error && (
        <Div className='error-message'>
          Please select a rating before submitting
        </Div>
      )}
      <Rate
        className='metrics-item'
        allowClear={false}
        count={3}
        value={value}
        onChange={onChange}
        characterRender={(star, p) => (
          <Div flex className='star-wrapper'>
            {star}
            <span className='star-desc'>
              {p.index === 0 && (
                <>
                  Needs
                  <br />
                  improvement
                </>
              )}
              {p.index === 1 && 'Acceptable'}
              {p.index === 2 && 'Good'}
            </span>
          </Div>
        )}
      />
    </Div>
  );
};

export default PerformanceMetrics;
