import React, { FC } from 'react';
import { Button } from 'antd';
import { Div } from '../../../../framework';

type Props = {
  onValue: (hireAgain: boolean) => void;
};

const HireAgain: FC<Props> = ({ onValue }) => {
  return (
    <Div flex style={{ flexDirection: 'column', alignItems: 'center' }}>
      <Div
        style={{
          textAlign: 'center',
          fontSize: 'large',
          fontWeight: 600,
          marginBottom: '1.5rem',
        }}
      >
        Would you hire this talent again?
      </Div>

      <Button
        type='primary'
        size='large'
        block
        style={{ width: '70%', marginBottom: '1rem' }}
        onClick={() => onValue(true)}
      >
        Yes
      </Button>

      <Button
        type='primary'
        size='large'
        block
        style={{ width: '70%', marginBottom: '1rem' }}
        onClick={() => onValue(false)}
      >
        No
      </Button>
    </Div>
  );
};

export default HireAgain;
