import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Image, Typography } from 'antd';
import Menu from '../../component/Menu/Menu';
import { telegramBotId } from '../../../config/app.config';
import { Div, Page } from '../../framework';
import { useResetScroll } from '../../helper/hook';
import { AUTH_ROUTES } from '../../route/constant';
import { RootState } from '../../store/store';
import { setMenuMode, setFlyoutOpen } from '../../store/app.slice';
import { setPartialSettings } from '../../store/user.slice';

import './ActivateTelegram.scss';

const { Paragraph, Title } = Typography;

const ActivateTelegramPage = () => {
  useResetScroll();

  const { authUser } = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!authUser?.exp) {
      sessionStorage.setItem('redirect', location.pathname);
      history.push(AUTH_ROUTES.TALENT_LOGIN);
    }
  }, []);

  useEffect(() => {
    dispatch(setFlyoutOpen({ isOpen: false }));
    dispatch(setMenuMode({ isShrank: true }));
  }, []);

  const handleActivateTelegram = () => {
    dispatch(setPartialSettings({ hasActivateTelegram: true }));
  };

  const telegramLink = `https://t.me/${telegramBotId}?start=${authUser.id}`;
  return (
    <Page className='page-activate-telegram'>
      <Div className='mobile-view'>
        <Menu />
        <Title level={4}>Activate Real-Time Notifications</Title>
        <Paragraph className='mb'>
          Activating Telegram notification could greatly improve your experience
          while using our service. You will get a notification whenever we found
          a role that fits you well.
        </Paragraph>
        <Image
          src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/telegram_match_msg.png'
          wrapperClassName='mb image'
        />
        <Paragraph className='mb'>
          Before continue, make sure you&apos;ve got the Telegram app installed
          and stay logged in.
        </Paragraph>
        <Paragraph className='mb'>
          Once you&apos;re all set, go ahead tap on the &quot;Activate&quot;
          button below!
        </Paragraph>

        <Image
          src='https://heycastme-assets.s3.ap-southeast-1.amazonaws.com/browser-prompt-telegram.jpg'
          wrapperClassName='mb image'
        />
        <Paragraph className='mb'>
          When prompt &quot;Continue to Telegram?&quot;,{' '}
          <b>tap on &quot;Continue&quot;</b>.
        </Paragraph>
        <Paragraph className='mb'>
          And finally, in Telegram, <b>press &quot;Start&quot;</b> at the bottom
          to activate real-time notifications.
        </Paragraph>
        <Button
          href={telegramLink}
          target='_blank'
          block
          type='primary'
          onClick={handleActivateTelegram}
        >
          Activate
        </Button>
      </Div>
    </Page>
  );
};

export default ActivateTelegramPage;
