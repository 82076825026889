import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
  CloseOutlined,
  CopyOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  DatePicker,
  Form,
  Input,
  Space,
  Spin,
  TimePicker,
} from 'antd';
import { RuleObject } from 'antd/lib/form';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import { areIntervalsOverlapping } from 'date-fns';
import moment, { Moment } from 'moment';
import { Div } from '../../../framework';
import { useActivityService } from '../../../service/activity.service';
import { Schedule } from '../../../helper/type';
import { HIRER_ROUTES } from '../../../route/constant';
import { RootState } from '../../../store/store';
import { setHirerSchedules } from '../../../store/hirer.slice';

import './ScheduleDetail.scss';

const MAX_DIFF_DATES = 7;

type Timeslot = { start: Moment; end: Moment };
type FormValue = {
  scheduleName?: string;
  scheduleItem: Array<{
    date: Moment;
    time: Array<{ slot: Timeslot }>;
  }>;
};

const ScheduleDetailPage = () => {
  const dispatch = useDispatch();
  const { loadStack } = useSelector((state: RootState) => state.app);
  const { mappedSchedules } = useSelector((state: RootState) => state.hirer);
  const { authHirer } = useSelector((state: RootState) => state.user);
  const activityService = useActivityService();
  const history = useHistory();
  const { type, scheduleId } = useParams<{
    type: 'create' | 'edit';
    scheduleId?: string;
  }>();

  const [form] = Form.useForm();
  const isLoading = loadStack.includes(`activity/schedule/get/${scheduleId}`);

  useEffect(() => {
    if (scheduleId && type === 'edit') {
      (async () => {
        try {
          const schedule: Schedule = mappedSchedules[scheduleId]
            ? { ...mappedSchedules[scheduleId] }
            : await activityService.getHirerSchedule(scheduleId);

          // transform api data to match form value structure
          const formValue: FormValue = {
            scheduleName: schedule.scheduleName,
            scheduleItem: Object.keys(schedule.slots)
              .sort()
              .map((date) => ({
                date: moment(date, 'YYYY-MM-DD'),
                time: [...schedule.slots[date]]
                  .sort((a, b) => a.start.localeCompare(b.start))
                  .map((timeslot: { start: string; end: string }) => ({
                    slot: {
                      start: moment(timeslot.start),
                      end: moment(timeslot.end),
                    },
                  })),
              })),
          };

          form.setFieldsValue(formValue);
        } catch (e) {
          // fail to load schedule, go back listing page
          history.push(HIRER_ROUTES.SCHEDULE_LISTING);
        }
      })();
    }
  }, []);

  // user no need to click "OK" to trigger value change
  const onSelectTime = (namepath: (string | number)[], time: Moment) => {
    form.setFieldValue(['scheduleItem', ...namepath], time);
  };
  const notEmptyListValidator = (message: string) => {
    return async (_: RuleObject, listItems: unknown[]) => {
      if (!listItems || listItems.length === 0) {
        return Promise.reject(new Error(message));
      }
    };
  };
  const overlapTimeValidator = (message: string) => {
    return async (
      _: RuleObject,
      listItems: FormValue['scheduleItem'][0]['time']
    ) => {
      const now = new Date();
      const normalizeDate = (time: Moment) =>
        moment(time)
          .set('year', now.getFullYear())
          .set('month', now.getMonth())
          .set('date', now.getDate());

      if (listItems) {
        const timeslots = listItems.filter((item) => Boolean(item));
        const overlap = [];
        for (let i = 0; i < timeslots.length - 1; i++) {
          for (let j = i + 1; j < timeslots.length; j++) {
            const { slot: slot1 } = timeslots[i];
            const { slot: slot2 } = timeslots[j];
            if (
              areIntervalsOverlapping(
                {
                  start: normalizeDate(slot1.start).toDate(),
                  end: normalizeDate(slot1.end).toDate(),
                },
                {
                  start: normalizeDate(slot2.start).toDate(),
                  end: normalizeDate(slot2.end).toDate(),
                }
              )
            ) {
              overlap.push(slot2);
            }
          }
        }
        if (overlap.length > 0) {
          const formatTime = (time: Moment) => time.format('hh:mm a');
          return Promise.reject(
            new Error(
              `${message} (${overlap
                .map(
                  (time) =>
                    `${formatTime(time.start)} - ${formatTime(time.end)}`
                )
                .join(', ')})`
            )
          );
        }
      }
    };
  };
  const slotStartEndValidator = (message: string) => {
    return async (
      _: RuleObject,
      listItems: FormValue['scheduleItem'][0]['time']
    ) => {
      if (listItems) {
        const timeslots = listItems.filter((item) => Boolean(item));
        const invalidSlot = [];
        for (let i = 0; i < timeslots.length; i++) {
          const { start, end } = timeslots[i].slot;
          if (start >= end) invalidSlot.push(timeslots[i].slot);
        }
        if (invalidSlot.length > 0) {
          const formatTime = (time: Moment) => time.format('hh:mm a');
          return Promise.reject(
            new Error(
              `${message} (${invalidSlot
                .map(
                  (time) =>
                    `${formatTime(time.start)} - ${formatTime(time.end)}`
                )
                .join(', ')})`
            )
          );
        }
      }
    };
  };
  const sameDateValidator = (message: string) => {
    return async (_: RuleObject, listItems: FormValue['scheduleItem']) => {
      if (listItems) {
        const count = listItems
          .filter((item) => item)
          .reduce((acc, cur) => {
            if (cur.date) {
              const date = cur.date.format('YYYY-MM-DD');
              acc[date] = (acc[date] ?? 0) + 1;
            }
            return acc;
          }, {} as Record<string, number>);
        const duplicates = Object.keys(count).filter((k) => count[k] > 1);
        if (duplicates.length > 0) {
          return Promise.reject(
            new Error(`${message} (${duplicates.join(', ')})`)
          );
        }
      }
    };
  };

  const onFinish = async (values: FormValue) => {
    const { scheduleItem, scheduleName } = values;
    const formatIsoString = (date: string, time: string) =>
      moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').toISOString();
    // transform form value structure to match api
    const scheduleSlots: Schedule['slots'] = scheduleItem.reduce(
      (acc, item) => {
        const date = item.date.format('YYYY-MM-DD');
        return {
          ...acc,
          [date]: item.time
            .map((t) => ({
              start: formatIsoString(date, t.slot.start.format('HH:mm')),
              end: formatIsoString(date, t.slot.end.format('HH:mm')),
            }))
            .sort((a, b) => a.start.localeCompare(b.start)),
        };
      },
      {}
    );

    const savedSchedule: Schedule = await activityService.saveSchedule(
      {
        hirerId: authHirer.hirerId,
        scheduleName: scheduleName || scheduleItem[0].date.format('YYYY-MM-DD'),
        slots: scheduleSlots,
      },
      scheduleId
    );

    dispatch(
      setHirerSchedules({
        scheduleId: savedSchedule.scheduleId,
        schedule: savedSchedule,
      })
    );
    history.push(HIRER_ROUTES.SCHEDULE_LISTING);
  };

  const isDateEditable = (itemName: number) => {
    const date = form.getFieldValue([
      'scheduleItem',
      itemName,
      'date',
    ]) as Moment;
    return !date || date.isAfter(Date.now());
  };

  const renderTimeslots = (itemName: number) => {
    const listNamePath = [itemName, 'time'];
    const isEditable = isDateEditable(itemName);
    return (
      <Form.List
        name={listNamePath}
        rules={[
          {
            validator: notEmptyListValidator(
              'At least one time slot must be added.'
            ),
          },
          {
            validator: overlapTimeValidator(
              'Overlapping time ranges found, please adjust the following time ranges:'
            ),
          },
          {
            validator: slotStartEndValidator(
              'Invalid timeslot, start time cannot greater than end time, please adjust the following timeslots:'
            ),
          },
        ]}
      >
        {(timeslots, { add, remove }, { errors }) => (
          <>
            {timeslots.map((slot) => {
              const slotStartPath = [slot.name, 'slot', 'start'];
              const slotEndPath = [slot.name, 'slot', 'end'];
              return (
                <Space
                  key={[...listNamePath, slot.name].join('-')}
                  align='baseline'
                  className='space-time-picker'
                >
                  <Form.Item
                    className='form-item-time-picker'
                    name={slotStartPath}
                    rules={[{ required: true, message: 'Missing start time.' }]}
                  >
                    <TimePicker
                      disabled={!isEditable}
                      allowClear={false}
                      showSecond={false}
                      format={'hh:mm a'}
                      minuteStep={5}
                      onSelect={(time) =>
                        onSelectTime(listNamePath.concat(slotStartPath), time)
                      }
                    />
                  </Form.Item>
                  <MinusOutlined onClick={() => remove(slot.name)} />
                  <Form.Item
                    className='form-item-time-picker'
                    name={slotEndPath}
                    rules={[{ required: true, message: 'Missing end time.' }]}
                  >
                    <TimePicker
                      disabled={!isEditable}
                      allowClear={false}
                      showSecond={false}
                      format={'hh:mm a'}
                      minuteStep={5}
                      onSelect={(time) =>
                        onSelectTime(listNamePath.concat(slotEndPath), time)
                      }
                    />
                  </Form.Item>

                  {isEditable ? (
                    <CloseOutlined
                      className='icon ms-auto'
                      onClick={() => remove(slot.name)}
                    />
                  ) : null}
                </Space>
              );
            })}
            {isEditable ? (
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add Time
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            ) : (
              <Div pv='m'></Div>
            )}
          </>
        )}
      </Form.List>
    );
  };

  return (
    <Div className='page-hirer-schedule-detail'>
      <Helmet>
        <title>Create Schedule - HeyCast.Me</title>
        <meta name='description' content='The right talent. The right part.' />
      </Helmet>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to='/hirer/home'>Dashboard</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={HIRER_ROUTES.SCHEDULE_LISTING}>Schedules</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {type === 'edit' ? 'Edit' : 'Create'} schedule
        </Breadcrumb.Item>
      </Breadcrumb>

      <Div pv='l'>
        <Title level={4}>{type === 'edit' ? 'Edit' : 'Create'} Schedule</Title>
        <Paragraph className='description'>
          Please specify your available date and time to run casting session.
          You would then be able to invite talents for casting based on this
          schedule.
        </Paragraph>
        <Paragraph className='description'>
          Invited talents would be able to choose timeslot based on your
          schedule available date and time.
        </Paragraph>
      </Div>

      <Spin spinning={isLoading}>
        <Form
          form={form}
          name='schedule'
          onFinish={onFinish}
          autoComplete='off'
          layout='vertical'
        >
          <Form.List
            name='scheduleItem'
            rules={[
              {
                validator: notEmptyListValidator(
                  'At least one date must be added.'
                ),
              },
              {
                validator: sameDateValidator('Each date can only appear once.'),
              },
            ]}
          >
            {(items, { add, remove }, { errors }) => (
              <>
                {items.map((item) => (
                  <Div key={item.key} flex style={{ flexDirection: 'column' }}>
                    <Space align='baseline' className='space-date-picker'>
                      <Form.Item
                        className='form-item-date-picker'
                        name={[item.name, 'date']}
                        rules={[{ required: true, message: 'Missing date.' }]}
                      >
                        <DatePicker
                          disabled={!isDateEditable(item.name)}
                          allowClear={false}
                          showToday={false}
                          disabledDate={(date) =>
                            date && date < moment().endOf('day')
                          }
                        />
                      </Form.Item>
                      <Div>
                        {items.length < MAX_DIFF_DATES ? (
                          <CopyOutlined
                            className='icon'
                            onClick={() =>
                              add({
                                ...form.getFieldValue([
                                  'scheduleItem',
                                  item.name,
                                ]),
                                date: '',
                              })
                            }
                          />
                        ) : null}
                        {isDateEditable(item.name) ? (
                          <CloseOutlined
                            className='icon'
                            onClick={() => remove(item.name)}
                          />
                        ) : null}
                      </Div>
                    </Space>
                    {renderTimeslots(item.name)}
                  </Div>
                ))}
                <Form.Item>
                  {items.length < MAX_DIFF_DATES ? (
                    <Button
                      type='dashed'
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Date
                    </Button>
                  ) : (
                    <div className='end-of-list'>
                      <span>You can only add 7 dates in a schedule</span>
                    </div>
                  )}
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            name='scheduleName'
            label={
              <span>
                Schedule Name <span className='span-optional'>(Optional)</span>
              </span>
            }
          >
            <Input placeholder='etc. Fast food ads casting' />
          </Form.Item>
          <Form.Item>
            <Button type='primary' block htmlType='submit'>
              {type === 'edit' ? 'Update' : 'Create'} Schedule
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Div>
  );
};

export default ScheduleDetailPage;
